<template>
    <div class="d-flex align-items-center mb-3 justify-content-center" aria-hidden="true">
      <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
      </div>
    </div>
    <div class="d-flex align-items-center mt-3 justify-content-center">
      <span class="fs-5 fw-bold text-success fw-bolder">wait a sec &nbsp;</span>
      <div class="spinner-grow text-success" role="status">
      <span class="visually-hidden">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-success" role="status">
      <span class="visually-hidden">Loading...</span>
      </div>&nbsp;
      <div class="spinner-grow text-success" role="status">
      <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- <h5 class="text-center text-success placeholder-glow">
      <span class="placeholder col-6"></span>
    </h5> -->
    <p class="text-center placeholder-glow mt-2">
       <span class="text-success placeholder col-6"></span>
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="text-success placeholder col-4"></span>
      <span class="text-success placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="text-success placeholder col-7"></span>
      <span class="text-success placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="text-success placeholder col-8"></span>
    </p>
</template>

<script>
export default {
    name: "Loading"

}
</script>

<style scoped>
.spinner-grow {
  height: 0.5rem !important;
  width: 0.5rem !important;
}

</style>