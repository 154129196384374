<template>
<CustomNav><BackButton :times="1"/> Settings</CustomNav>
<TabFour >
     <template v-slot:title1>
      Profile  
    </template>
    <template v-slot:title2>
      Stores  
    </template>
    <template v-slot:title3>
      Day Transaction Heading
    </template>
     <template v-slot:title4>
      Month Transaction Heading
    </template>
    <template v-slot:m-title1>
      Profile  
    </template>
    <template v-slot:m-title2>
      Stores  
    </template>
    <template v-slot:m-title3>
      Day
    </template>
     <template v-slot:m-title4>
      Month
    </template>
    <template v-slot:content1>
        <!-- <div class="row mb-3">
            <div class="col-3 fw-bold">Name</div>
            <div class="col-9"></div>
        </div> -->
        <div v-if="showProfile">
            <div class="row mb-3">
                 <p class="fs-5 fw-bold">Profile</p>
                <div class="col-3 fw-bold">Email</div>
                <div class="col-9">{{email}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-3"></div>
                <div class="col-9"><button @click="changePassword" class="btn btn-sm btn-primary">Change Password</button></div>
            </div>
        </div>
        <div v-if="showChangePassword">
            <template v-if="isPasswordChangeLoading">
                <Loading />
            </template>
            <template v-else>
                <div class="row">
                <form @submit.prevent="handleChangePassword">
                    <p class="fs-5 fw-bold">Change Password</p>
                    <div class="row text-danger error-msg" v-if="errors && errors.length">
                        <div class="col-1">
                            <BIconExclamationCircle class="fs-5" />
                        </div>
                        <div class="col-11">
                            <p v-for="error of errors" :key="error" class="text-danger">
                            {{error.message}}
                            </p>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="oldPassword" class="col-md-4 col-form-label fw-bold">Old Password</label>
                        <div class="col-md-6">
                        <input type="password" class="form-control" id="oldPassword">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="newPassword" class="col-md-4 col-form-label fw-bold">New Password</label>
                        <div class="col-md-6">
                        <input type="password" class="form-control" id="newPassword">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="confirmNewPassword" class="col-md-4 col-form-label fw-bold">Confirm New Password</label>
                        <div class="col-md-6">
                        <input type="password" class="form-control" id="confirmNewPassword">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 offset-md-4 text-center">
                            <button type="submit" class="btn btn-primary">Update</button>
                            <button @click="cancelChangePassword" class="ms-2 btn btn-outline-primary">Cancel</button>
                        </div>
                    
                    </div>
                </form>
            </div>
            </template>
            
        </div>    
    </template>
    <template v-slot:content2>
        <div class="d-flex justify-content-between align-items-center">
             <p class="fs-5 fw-bold">Store List</p>
             <p><button @click="toggleShowAddStore" class="fw-bold fs-2 link"><BIconPlusCircle /></button></p>
        </div>
        <div class="row text-danger error-msg" v-if="errors && errors.length">
            <div class="col-1">
                <BIconExclamationCircle class="fs-5" />
            </div>
            <div class="col-11">
                <p v-for="error of errors" :key="error" class="text-danger">
                {{error.message}}
                </p>
            </div>
        </div>
         <template v-if="isStoreLoading">
                    <Loading />
        </template>
        <template v-else>
            <table class="table table-striped">
            <thead>
                <th><p class="mb-0">Name</p></th>
                <th><p class="mb-0">Address</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
               
            </thead>
            <tbody>
               
                <tr v-if="showAddStore">
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" id="add-new-store" ></p></td>
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" id="add-new-address"></p></td>
                     <td>&nbsp;</td>
                    <td class="fw-bold fs-6">
                        <button @click="addStore" id="active-edit-check"  class="link" ><BIconCheckLg /></button>  
                    </td>
                   
                    <td>
                         <button @click="toggleShowAddStore" id="active-edit-cancel" class="link text-danger"><BIconXLg /></button>
                    </td>
                   
                   
                </tr>
                <tr v-for="item in store_data" :key="item.id" :id="item.id">
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" :id="'store-'+item.id" :value="item.store_name" disabled readonly></p></td>
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" :id="'address-'+item.id" name="address" :value="item.address" disabled readonly></p></td>
                    <td class="fw-bold fs-6">
                        <button @click="handleStoreUpdate(item)" :id="'active-edit-check-'+item.id"  class="link d-none" ><BIconCheckLg /></button>
                        
                    </td>
                    <td class="fw-bold fs-6">
                         <button @click="cancelStoreUpdate(item)" :id="'active-edit-cancel-'+item.id" class="link text-danger d-none"><BIconXLg /></button>
                        <button @click="activateStoreUpdate(item)" :id="'active-edit-'+item.id" class="bg-none"><BIconPencilSquare /></button>
                    </td>
                    <td class="fw-bold fs-6"><button @click="deleteStore(item)" class="bg-none"><BIconTrash /></button></td>
                </tr>
            </tbody>
        </table>
        </template>
        
    </template>
    <template v-slot:content3>
        <div class="d-flex justify-content-between align-items-center">
             <p class="fs-5 fw-bold">Day Transaction Headings</p>
             <p><button @click="toggleShowAddDayTitle" class="fw-bold fs-2 link"><BIconPlusCircle /></button></p>
        </div>
        <div class="row text-danger error-msg" v-if="errors && errors.length">
            <div class="col-1">
                <BIconExclamationCircle class="fs-5" />
            </div>
            <div class="col-11">
                <p v-for="error of errors" :key="error" class="text-danger">
                {{error.message}}
                </p>
            </div>
        </div>
         <template v-if="isDayTitleLoading">
                    <Loading />
        </template>
        <template v-else>
            <table class="table table-striped">
            <thead>
                <th><p class="mb-0">Title</p></th>
                <th><p class="mb-0">Type</p></th>
                <th><p class="mb-0">Stores</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
               
            </thead>
            <tbody>
               
                <tr v-if="showAddDayTitle">
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" id="add-day-title" ></p></td>
                    <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm" id="add-day-title-type">
                                <option value="1" selected>Income</option>
                                <option value="2">Expense</option>
                                <option value="3">Others</option>
                                <option value="4">Remark</option>
                            </select>
                        </p>
                    </td>
                    <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm" id="add-day-title-store">
                                <template v-for="store in store_data" :key="store.id">
                                <option :value="store.id">{{store.store_name}}</option>
                                </template>
                            </select>
                        </p>
                    </td>
                    <td></td>
                    <!-- <td><p v-for="store in item.store" :key="store">{{store}},</p></td> -->
                     <td>&nbsp;</td>
                    <td class="fw-bold fs-6">
                        <button @click="handleAddDayTitle" id="day-active-edit-check"  class="link" ><BIconCheckLg /></button>  
                    </td>
                   
                    <td>
                         <button @click="toggleShowAddDayTitle" id="day-active-edit-cancel" class="link text-danger"><BIconXLg /></button>
                    </td>
                   
                   
                </tr>
                <tr v-for="item in dayTitles" :key="item.id" :id="item.id">
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" :id="'day-title-'+item.id" :value="item.title" disabled readonly></p></td>
                    <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm inline-edit" aria-label="Default select" :id="'day-title-type-'+item.id" disabled readonly>
                                <template v-if="item.transaction_type==1">
                                        <option value="1" selected>Income</option>
                                        <option value="2">Expense</option>
                                        <option value="3">Others</option>
                                        <option value="4">Remark</option>
                                </template>
                                <template v-if="item.transaction_type==2">
                                        <option value="1">Income</option>
                                        <option value="2" selected>Expense</option>
                                        <option value="3">Others</option>
                                        <option value="4">Remark</option>
                                </template>
                                <template v-if="item.transaction_type==3">
                                         <option value="1">Income</option>
                                        <option value="2">Expense</option>
                                        <option value="3" selected>Others</option>
                                        <option value="4">Remark</option>
                                </template>
                                 <template v-if="item.transaction_type==4">
                                         <option value="1">Income</option>
                                        <option value="2">Expense</option>
                                        <option value="3">Others</option>
                                        <option value="4" selected>Remark</option>
                                </template>
                            </select>
                        </p>
                    </td>
                     <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm inline-edit" :id="'day-title-store-'+item.id" disabled readonly>

                                <template v-for="store in store_data" :key="store.id">
                                    <template v-if="item.store==store.id">
                                        <option :value="store.id" selected>{{store.store_name}}</option>
                                    </template>
                                    <template v-else>
                                        <option :value="store.id">{{store.store_name}}</option>
                                    </template>
                                </template>>
                            </select>
                        </p>
                    </td>
                    <td class="fw-bold fs-6">
                        <button @click="handleDayTitleUpdate(item)" :id="'day-active-edit-check-'+item.id"  class="link d-none" ><BIconCheckLg /></button>
                        
                    </td>
                    <td class="fw-bold fs-6">
                         <button @click="cancelDayTitleUpdate(item)" :id="'day-active-edit-cancel-'+item.id" class="link text-danger d-none"><BIconXLg /></button>
                        <button @click="activateDayTitleUpdate(item)" :id="'day-active-edit-'+item.id" class="bg-none"><BIconPencilSquare /></button>
                    </td>
                    <td class="fw-bold fs-6"><button @click="deleteDayTitle(item)" class="bg-none"><BIconTrash /></button></td>
                </tr>
            </tbody>
        </table>
        </template>
        
    </template>
    <template v-slot:content4>
        <div class="d-flex justify-content-between align-items-center">
             <p class="fs-5 fw-bold">Month Transaction Headings</p>
             <p><button @click="toggleShowAddMonthTitle" class="fw-bold fs-2 link"><BIconPlusCircle /></button></p>
        </div>
        <div class="row text-danger error-msg" v-if="errors && errors.length">
            <div class="col-1">
                <BIconExclamationCircle class="fs-5" />
            </div>
            <div class="col-11">
                <p v-for="error of errors" :key="error" class="text-danger">
                {{error.message}}
                </p>
            </div>
        </div>
         <template v-if="isMonthTitleLoading">
                    <Loading />
        </template>
        <template v-else>
            <table class="table table-striped">
            <thead>
                <th><p class="mb-0">Title</p></th>
                <th><p class="mb-0">Type</p></th>
                <th><p class="mb-0">Store</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
                <th><p class="mb-0">&nbsp;</p></th>
               
            </thead>
            <tbody>
               
                <tr v-if="showAddMonthTitle">
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" id="add-month-title" ></p></td>
                    <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm" id="add-month-title-type">
                                <option value="1" selected>Income</option>
                                <option value="2">Expense</option>
                                <option value="3">Others</option>
                            </select>
                        </p>
                    </td>
                    <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm" id="add-month-title-store">
                                <template v-for="store in store_data" :key="store.id">
                                <option :value="store.id">{{store.store_name}}</option>
                                </template>
                            </select>
                        </p>
                    </td>
                   
                     <td>&nbsp;</td>
                    <td class="fw-bold fs-6">
                        <button @click="handleAddMonthTitle" id="month-active-edit-check"  class="link" ><BIconCheckLg /></button>  
                    </td>
                   
                    <td>
                         <button @click="toggleShowAddMonthTitle" id="month-active-edit-cancel" class="link text-danger"><BIconXLg /></button>
                    </td>
                   
                   
                </tr>
                <tr v-for="item in monthTitles" :key="item.id" :id="item.id">
                    <td><p class="mb-0"><input class="form-control form-control-sm inline-edit" type="text" :id="'month-title-'+item.id" :value="item.title" disabled readonly></p></td>
                    <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm inline-edit" aria-label="Default select" :id="'month-title-type-'+item.id" disabled readonly>
                                <template v-if="item.transaction_type==1">
                                        <option value="1" selected>Income</option>
                                        <option value="2">Expense</option>
                                        <option value="3">Others</option>
                                </template>
                                <template v-if="item.transaction_type==2">
                                        <option value="1">Income</option>
                                        <option value="2" selected>Expense</option>
                                        <option value="3">Others</option>
                                </template>
                                <template v-if="item.transaction_type==3">
                                         <option value="1">Income</option>
                                        <option value="2">Expense</option>
                                        <option value="3" selected>Others</option>
                                </template>
                            </select>
                        </p>
                    </td>
                     <td>
                        <p class="mb-0">
                            <select class="form-select form-control-sm inline-edit" :id="'month-title-store-'+item.id" disabled readonly>

                                <template v-for="store in store_data" :key="store.id">
                                    <template v-if="item.store==store.id">
                                        <option :value="store.id" selected>{{store.store_name}}</option>
                                    </template>
                                    <template v-else>
                                        <option :value="store.id">{{store.store_name}}</option>
                                    </template>
                                </template>>
                            </select>
                        </p>
                    </td>
                    <td class="fw-bold fs-6">
                        <button @click="handleMonthTitleUpdate(item)" :id="'month-active-edit-check-'+item.id"  class="link d-none" ><BIconCheckLg /></button>
                        
                    </td>
                    <td class="fw-bold fs-6">
                         <button @click="cancelMonthTitleUpdate(item)" :id="'month-active-edit-cancel-'+item.id" class="link text-danger d-none"><BIconXLg /></button>
                        <button @click="activateMonthTitleUpdate(item)" :id="'month-active-edit-'+item.id" class="bg-none"><BIconPencilSquare /></button>
                    </td>
                    <td class="fw-bold fs-6"><button @click="deleteMonthTitle(item)" class="bg-none"><BIconTrash /></button></td>
                </tr>
            </tbody>
        </table>
        </template>
        
    </template>
</TabFour>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/bootstrap/loading.vue'
import CustomNav from '@/components/CustomNav.vue'
import TabFour from '@/components/bootstrap/tab-4.vue'
import BackButton from "@/components/BackButton.vue";
import { 
    BIconCheck2Circle, 
    BIconExclamationCircle, 
    BIconPencilSquare, 
    BIconTrash,
    BIconXLg,
    BIconCheckLg,
    BIconPlusCircle,
    } 
from 'bootstrap-icons-vue';
import { faSmileWink } from '@fortawesome/free-solid-svg-icons'
export default {
    name: "Settings",
    components:{
        CustomNav,
        TabFour,
        BackButton,
        Loading,
        BIconExclamationCircle,
        BIconTrash,
        BIconPencilSquare,
        BIconXLg,
        BIconCheckLg,
        BIconPlusCircle,
    },
    data(){
        return {
            email: this.$store.state.email,
            showProfile: true,
            showChangePassword: false,
            store_data:null,
            // storeEditing:false,
            showAddStore:false,
            errors:[],
            isStoreLoading:false,
            isPasswordChangeLoading:false,
            dayTitles:null,
            isDayTitleLoading:false,
            showAddDayTitle:false,
            monthTitles:null,
            isMonthTitleLoading:false,
            showAddMonthTitle:false,
            // dayTitleEditing:false
    
        }
    },
    methods: {
         getStoreList(){
            axios
            .get(`store/list/`)
            .then(res=>{
            // console.log(res)
                this.store_data = res.data
            //  console.log(this.store_data)
             
            })
        },
         getDayTitles() {
            axios.get(`store/day/title/`).then((res) => {
                this.dayTitles = res.data;
                console.log("Day Titles", this.dayTitles)
            });
        },
         getMonthTitles() {
            axios.get(`store/month/title/`).then((res) => {
                this.monthTitles = res.data;
                console.log("Month Titles", this.monthTitles)
            });
        },
        toggleShowAddStore(){
            this.showAddStore = !this.showAddStore
        },
        async addStore(){
            this.errors=[]
            const store_name = document.getElementById("add-new-store").value
            const address = document.getElementById("add-new-address").value
            if(!store_name){
                this.errors.push({
                    'message': 'Store name is required'
                })
                return false
            }
            if(!address){
                this.errors.push({
                    'message': 'Store Address is required'
                })
                return false
            }
            console.log("start loading")
            this.isStoreLoading=true
            const formData = {
                store_name: store_name,
                address: address,
            }
            console.log("starting axios")
            await axios
            .post(`store/list/`, formData)
            .then(res=>{
                this.showAddStore = !this.showAddStore
                console.log("starting getstore")
                this.getStoreList()
                console.log("finish getstore")
                 this.$toast.open({
                    message: res.data.success,
                    type: "success",
                 });
             
            })
            console.log("finish loading")
            this.isStoreLoading=false
           
        },
        async deleteStore(item){
            const id = item.id
            this.isStoreLoading=true
            await axios
            .delete(`store/detail/`+id+`/`)
            .then(res=>{
                this.getStoreList()
                 this.$toast.open({
                    message: res.data.success,
                    type: "success",
                 });
             
            })
            this.isStoreLoading=false

        },
        cancelChangePassword(){
            this.showProfile=true,
            this.showChangePassword=false
        },
        changePassword(){
            this.showProfile=false,
            this.showChangePassword=true
        },
        async handleChangePassword(){
            this.errors=[]
            const old_password = document.getElementById("oldPassword").value
            const new_password1 = document.getElementById("newPassword").value
            const new_password2 = document.getElementById("confirmNewPassword").value
            if(!old_password){
                this.errors.push({
                    'message':'Old password is required'
                })
                return false
            }
            if(!new_password1 || !new_password2){
                this.errors.push({
                    'message':'New password or Confirm password field is blank'
                })
                return false
            }
            if(new_password1!==new_password2){
                this.errors.push({
                    'message': 'New password and Confirm password must match'
                })
                return faSmileWink
            }
            const formData = {
                old_password: old_password,
                new_password1: new_password1,
                new_password2:new_password2
            }
            this.isPasswordChangeLoading=true
            await axios
            .post(`password/change/`, formData)
            .then(res=>{
                // console.log(res.data)
                 this.$toast.open({
                    message: res.data.detail,
                    type: "success",
                 });
                this.isPasswordChangeLoading=false
                this.cancelChangePassword()
            }).catch(e=>{
                // console.log("e",e)
                // console.log("e.response",e.response)
                // console.log("e.response.data",e.response.data)
                        Object.entries(e.response.data).map(item => {
                    // console.log(item[1][0])
                    this.errors.push({
                        "message": item[1][0]
                    })
                })
                this.isPasswordChangeLoading=false
                // return false
            })
            
        },
        activateStoreUpdate(item) {
            const id = item.id
            const edit_check = "active-edit-check-"+id
            const edit_cancel = "active-edit-cancel-"+id
            const edit = "active-edit-"+id
            const formNameId = "store-"+id
            const formAddressId = "address-"+id
            document.getElementById(formNameId).removeAttribute("readonly");
            document.getElementById(formNameId).removeAttribute("disabled");
            document.getElementById(formAddressId).removeAttribute("readonly");
            document.getElementById(formAddressId).removeAttribute("disabled");
            var check_element= document.getElementById(edit_check)
            var cancel_element= document.getElementById(edit_cancel)
            var edit_element= document.getElementById(edit)
            // console.log(check_element)
            // console.log(cancel_element)
            check_element.classList.remove("d-none")
            cancel_element.classList.remove("d-none")
            edit_element.classList.add("d-none")
        },
        cancelStoreUpdate(item) {
            const id = item.id
            const edit_check = "active-edit-check-"+id
            const edit_cancel = "active-edit-cancel-"+id
            const edit = "active-edit-"+id
            const formNameId = "store-"+id
            const formAddressId = "address-"+id
            document.getElementById(formNameId).setAttribute("readonly", "readonly");
            document.getElementById(formNameId).setAttribute("disabled", "disabled");
            document.getElementById(formAddressId).setAttribute("readonly", "readonly");
            document.getElementById(formAddressId).setAttribute("disabled", "disabled");
            var check_element= document.getElementById(edit_check)
            var cancel_element= document.getElementById(edit_cancel)
            var edit_element= document.getElementById(edit)
            // console.log(check_element)
            // console.log(cancel_element)
            check_element.classList.add("d-none")
            cancel_element.classList.add("d-none")
            edit_element.classList.remove("d-none")
        },
        handleStoreUpdate(item){
            const id = item.id
            const user = item.user
            const formNameId = "store-"+id
            const formAddressId = "address-"+id
            document.getElementById(formNameId).removeAttribute("disabled");
            document.getElementById(formAddressId).removeAttribute("disabled");
            const changed_store_name = document.getElementById(formNameId).value
            const changed_address = document.getElementById(formAddressId).value
            console.log("item", item)
            const formData = {
                id: id,
                user: user,
                store_name: changed_store_name,
                address: changed_address

            }
            console.log("formData", formData)
        },
        toggleShowAddDayTitle(){
            this.errors=[]
            this.showAddDayTitle = !this.showAddDayTitle
        },
        async handleAddDayTitle(){
            this.errors=[]
            const title = document.getElementById("add-day-title").value
            const type = document.getElementById("add-day-title-type").value
            const store = document.getElementById("add-day-title-store").value
            const formData = {
                title: title,
                transaction_type:type,
                store: store
            }
            this.isDayTitleLoading=true
            await axios
            .post(`store/day/title/`, formData)
            .then(res=>{
                this.$toast.open({
                    message: res.data.success,
                    type: "success",
                 });
           
            })
            this.getDayTitles()
            this.toggleShowAddDayTitle()
            this.isDayTitleLoading=false

        },
        activateDayTitleUpdate(item) {
            this.errors=[]
            const id = item.id
            const edit_check = "day-active-edit-check-"+id
            const edit_cancel = "day-active-edit-cancel-"+id
            const edit = "day-active-edit-"+id
            const title_id = "day-title-"+id
            const type_id = "day-title-type-"+id
            const store = "day-title-store-"+id
            document.getElementById(title_id).removeAttribute("readonly");
            document.getElementById(title_id).removeAttribute("disabled");
            document.getElementById(type_id).removeAttribute("readonly");
            document.getElementById(type_id).removeAttribute("disabled");
            document.getElementById(type_id).classList.remove("inline-edit")
            document.getElementById(store).removeAttribute("readonly");
            document.getElementById(store).removeAttribute("disabled");
            document.getElementById(store).classList.remove("inline-edit")
            var check_element= document.getElementById(edit_check)
            var cancel_element= document.getElementById(edit_cancel)
            var edit_element= document.getElementById(edit)
            // console.log(check_element)
            // console.log(cancel_element)
            check_element.classList.remove("d-none")
            cancel_element.classList.remove("d-none")
            edit_element.classList.add("d-none")
        },
        cancelDayTitleUpdate(item) {
            this.errors=[]
            const id = item.id
            const edit_check = "day-active-edit-check-"+id
            const edit_cancel = "day-active-edit-cancel-"+id
            const edit = "day-active-edit-"+id
            const title_id = "day-title-"+id
            const type_id = "day-title-type-"+id
            const store = "day-title-store-"+id
            document.getElementById(title_id).setAttribute("readonly", "readonly");
            document.getElementById(title_id).setAttribute("disabled", "disabled");
            document.getElementById(type_id).setAttribute("readonly", "readonly");
            document.getElementById(type_id).setAttribute("disabled", "disabled");
            document.getElementById(type_id).classList.add("inline-edit")
            document.getElementById(store).setAttribute("readonly", "readonly");
            document.getElementById(store).setAttribute("disabled", "disabled");
            document.getElementById(store).classList.add("inline-edit")
            var check_element= document.getElementById(edit_check)
            var cancel_element= document.getElementById(edit_cancel)
            var edit_element= document.getElementById(edit)
            // console.log(check_element)
            // console.log(cancel_element)
            check_element.classList.add("d-none")
            cancel_element.classList.add("d-none")
            edit_element.classList.remove("d-none")
        },
        async handleDayTitleUpdate(item){
            this.errors=[]
            const id = item.id
            const title_id = "day-title-"+id
            const type_id = "day-title-type-"+id
            const store_id = "day-title-store-"+id

            const title = document.getElementById(title_id).value
            const type = document.getElementById(type_id).value
            const store = document.getElementById(store_id).value
            if(!title){
                this.errors.push({
                    'message': 'Title is required'
                })
                return false
            }
            const formData = {
                title: title,
                transaction_type: type,
                store: store
            }
            this.isDayTitleLoading=true
            await axios
            .put(`store/day/title/`+id+`/`, formData)
            .then(res=>{
                this.$toast.open({
                    message: res.data.success,
                    type: "success",
                 });
           
            })
            // this.cancelDayTitleUpdate(item) 
            this.getDayTitles()
            this.isDayTitleLoading=false
        },
        async deleteDayTitle(item){
            const id = item.id
            this.isDayTitleLoading = true
            await axios
            .delete(`store/day/title/`+id+`/`)
            .then(res=>{
                this.$toast.open({
                    message: res.data.success,
                    type: "success",
                });
            })
            this.getDayTitles()
            this.isDayTitleLoading=false
        },

        toggleShowAddMonthTitle(){
            this.errors=[]
            this.showAddMonthTitle = !this.showAddMonthTitle
        },
        async handleAddMonthTitle(){
            this.errors=[]
            const title = document.getElementById("add-month-title").value
            const type = document.getElementById("add-month-title-type").value
            const store = document.getElementById("add-month-title-store").value
            const formData = {
                title: title,
                transaction_type:type,
                store:store
            }
            this.isMonthTitleLoading=true
            await axios
            .post(`store/month/title/`, formData)
            .then(res=>{
                this.$toast.open({
                    message: res.data.success,
                    type: "success",
                 });
           
            })
            this.getMonthTitles()
            this.toggleShowAddMonthTitle()
            this.isMonthTitleLoading=false

        },
        activateMonthTitleUpdate(item) {
            this.errors=[]
            const id = item.id
            const edit_check = "month-active-edit-check-"+id
            const edit_cancel = "month-active-edit-cancel-"+id
            const edit = "month-active-edit-"+id
            const title_id = "month-title-"+id
            const type_id = "month-title-type-"+id
            const store_id = "month-title-store-"+id
            document.getElementById(title_id).removeAttribute("readonly");
            document.getElementById(title_id).removeAttribute("disabled");
            document.getElementById(type_id).removeAttribute("readonly");
            document.getElementById(type_id).removeAttribute("disabled");
            document.getElementById(type_id).classList.remove("inline-edit")
            document.getElementById(store_id).removeAttribute("readonly");
            document.getElementById(store_id).removeAttribute("disabled");
            document.getElementById(store_id).classList.remove("inline-edit")
            var check_element= document.getElementById(edit_check)
            var cancel_element= document.getElementById(edit_cancel)
            var edit_element= document.getElementById(edit)
            // console.log(check_element)
            // console.log(cancel_element)
            check_element.classList.remove("d-none")
            cancel_element.classList.remove("d-none")
            edit_element.classList.add("d-none")
        },
        cancelMonthTitleUpdate(item) {
            this.errors=[]
            const id = item.id
            const edit_check = "month-active-edit-check-"+id
            const edit_cancel = "month-active-edit-cancel-"+id
            const edit = "month-active-edit-"+id
            const title_id = "month-title-"+id
            const type_id = "month-title-type-"+id
            const store_id = "month-title-store-"+id
            document.getElementById(title_id).setAttribute("readonly", "readonly");
            document.getElementById(title_id).setAttribute("disabled", "disabled");
            document.getElementById(type_id).setAttribute("readonly", "readonly");
            document.getElementById(type_id).setAttribute("disabled", "disabled");
            document.getElementById(type_id).classList.add("inline-edit")
            document.getElementById(store_id).setAttribute("readonly", "readonly");
            document.getElementById(store_id).setAttribute("disabled", "disabled");
            document.getElementById(store_id).classList.add("inline-edit")
            var check_element= document.getElementById(edit_check)
            var cancel_element= document.getElementById(edit_cancel)
            var edit_element= document.getElementById(edit)
            // console.log(check_element)
            // console.log(cancel_element)
            check_element.classList.add("d-none")
            cancel_element.classList.add("d-none")
            edit_element.classList.remove("d-none")
        },
        async handleMonthTitleUpdate(item){
            this.errors=[]
            const id = item.id
            const title_id = "month-title-"+id
            const type_id = "month-title-type-"+id
            const store_id = "month-title-store-"+id

            const title = document.getElementById(title_id).value
            const type = document.getElementById(type_id).value
            const store = document.getElementById(store_id).value
            if(!title){
                this.errors.push({
                    'message': 'Title is required'
                })
                return false
            }
            const formData = {
                title: title,
                transaction_type: type,
                store:store
            }
            this.isMonthTitleLoading=true
            await axios
            .put(`store/month/title/`+id+`/`, formData)
            .then(res=>{
                this.$toast.open({
                    message: res.data.success,
                    type: "success",
                 });
           
            })
            // this.cancelDayTitleUpdate(item) 
            this.getMonthTitles()
            this.isMonthTitleLoading=false
        },
        async deleteMonthTitle(item){
            const id = item.id
            this.isMonthTitleLoading = true
            await axios
            .delete(`store/month/title/`+id+`/`)
            .then(res=>{
                this.$toast.open({
                    message: res.data.success,
                    type: "success",
                });
            })
            this.getMonthTitles()
            this.isMonthTitleLoading=false
        }

    },
    created(){
        this.getStoreList()
        this.getDayTitles()
        this.getMonthTitles()
    }

}
</script>

<style>

</style>