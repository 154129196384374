<template>
<ul class="nav nav-tabs with-caret" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link active"
        id="pills-one-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-one"
        type="button"
        role="tab"
        aria-controls="pills-one"
        aria-selected="true"
      >
        <slot name="title1">Tab One</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-two-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-two"
        type="button"
        role="tab"
        aria-controls="pills-two"
        aria-selected="false"
      >
       <slot name="title2">Tab Two</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-three-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-three"
        type="button"
        role="tab"
        aria-controls="pills-three"
        aria-selected="false"
      >
       <slot name="title3">Tab Three</slot>
      </button>
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="pills-one"
      role="tabpanel"
      aria-labelledby="pills-one"
    >
    <div class="d-flex justify-content-between">
      <!-- <div>&nbsp;</div> -->
      <!-- <div class="ms-4"><h6><slot name="title1">Tab One</slot></h6></div> -->
      <div>
        <slot name="btn1"></slot>
      </div>
    </div>
    <!-- <hr> -->
    <slot name="content1">Content One</slot>
    </div>
    
    <div
      class="tab-pane fade"
      id="pills-two"
      role="tabpanel"
      aria-labelledby="pills-two"
    >
      <div class="d-flex title-block justify-content-between">
        <!-- <div>&nbsp;</div> -->
        <!-- <div class="ms-4"><h6><slot name="title2">Tab Two</slot></h6></div> -->
        <div>
        <slot name="btn2"></slot>
      </div>
      </div>
      <!-- <hr> -->
      <slot name="content2">Content Two</slot>
     
    </div>
    <div
      class="tab-pane fade"
      id="pills-three"
      role="tabpanel"
      aria-labelledby="pills-three"
    >
      <div class="d-flex title-block justify-content-between">
        <!-- <div>&nbsp;</div> -->
        <!-- <div class="ms-4"><h6><slot name="title2">Tab Two</slot></h6></div> -->
        <div>
        <slot name="btn3"></slot>
      </div>
      </div>
      <!-- <hr> -->
      <slot name="content3">Content Three</slot>
     
    </div>
  </div>
</template>

<script>
export default {
    name: "TabThree"

}
</script>

<style>

</style>