<template>
  
</template>

<script>
export default {
    name: "PasswordReset",
    data() {
        return {
            // uid:"",
            // token:""
        }
    },
   
   beforeCreate(){
       const obj = this.$route.params
       this.$store.commit('setResetPassword', obj)
        this.$router.push({ name: "PasswordResetForm" });
       console.log("before create",this.$route.params)
   },
  //   mounted(){
  //      const obj = this.$route.params
  //      this.$store.commit('setResetPassword', obj)

  //      console.log("mount",this.$route.params)
  //  },
  //   created(){
  //      const obj = this.$route.params
  //      this.$store.commit('setResetPassword', obj)

  //      console.log("created",this.$route.params)
  //  }
}


</script>

<style>

</style>