<template>
 
    <a href="#" class="color-default" @click="$emit('modalOpen')"  data-bs-toggle="modal" :data-bs-target="bs_target" ><slot name="icon"></slot></a>

  <div
    class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" 
    :id="modal_id"
    tabindex="-1"
    :aria-labelledby="aria"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content bg-theme">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title text-center">
            <slot name="title">Modal Title</slot>
          </h5>
          <a
            href="#"
            class="icon-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :id="close_button"
          >
             <!-- <font-awesome-icon class="icon-close" :icon="closeIcon" /> -->
          </a>
        </div>
        <div class="modal-body">
          <div class="container">
            <slot name="content"> Modal Content </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "Modal",
  components:{
       FontAwesomeIcon,

  },
  props : ["modal_id", 'bs_target', "aria", "close_button"],
  emits: ['modalOpen'],
  data(){
      return{
           deleteIcon: faTrashAlt,
           closeIcon: faTimes,
           
           id:"",
      }
  },
};
</script>

<style scoped>
.modal-header, .modal-footer {
   border:none !important;
}
.close-icon{
  position:absolute;
  top:-10px;
  right:-5px;
}

</style>
