<template>
  <transition appear>
   <div>
       <StoreList
        @updateDayTransactions ="updateDayTransactions"
        @updateMonthTransactions="updateMonthTransactions"
        :dayTransactions="dayTransactions"
        :monthTransactions="monthTransactions"
        :isLoading="isLoading"   
        :storeId="storeId"
        :storeDetail="storeDetail"    
      />
  </div>
  </transition>
  <!-- :title="title"
    :urlTitle="urlTitle"
    :detailUrl="detailUrl"
    :requestType="requestType" -->
</template>

<script>
import StoreList from "@/components/Store/StoreList.vue";
import HomeList from "@/components/Home/List.vue";
import axios from "axios";


export default {
  name: "Transactions",
  components: {
    StoreList,
    HomeList
  },
  data() {
    return {
      dayTransactions: {},
      monthTransactions: {},
      isMultiStore: false,
      isSingleStore: true,
      storeId: null,
      storeDetail: {},
      isLoading: true,      
    };
  },
  methods: {
    // getTransaction() {
    //   axios
    //     .get(`store/day/transaction/` + this.storeId + `/`)
    //     .then((res) => {
    //       this.dayTransactions = res.data.results;
    //       console.log("Daily Transaction", res.data.results)
    //       //  console.log(this.transactions[0]['store_title'])
    //     });

    //   axios
    //     .get(`store/month/transaction/store/` + this.storeId + `/`)
    //     .then((res) => {
    //       this.monthTransactions = res.data.results;
    //       // console.log("Monthly Transaction", res.data.results)
    //       this.isLoading=false
    //     });
      
    //   axios
    //     .get(`store/detail/` + this.storeId + `/`)
    //     .then((res) => {
    //       this.storeDetail = res.data
    //     });
    // },
   async getDayTransactions(){
      console.log("day start")
      await axios
        .get(`store/day/transaction/` + this.storeId + `/`)
        .then((res) => {
          this.dayTransactions = res.data.results;
          console.log("Daily Transaction", res.data.results)
          //  this.isLoading=false
          //  console.log(this.transactions[0]['store_title'])
        });
    },
   async getMonthTransactions(){
      console.log("month start")
     await  axios
        .get(`store/month/transaction/store/` + this.storeId + `/`)
        .then((res) => {
          this.monthTransactions = res.data.results;
          console.log("Monthly Transaction", res.data.results)
          // this.fetchComplete = true;
        });
    },
  async  getStoreDetails(){
      console.log("store start")
      await axios
        .get(`store/detail/` + this.storeId + `/`)
        .then((res) => {
          this.storeDetail = res.data
          console.log("Store Details", this.storeDetail)
        });
    },
    async updateDayTransactions(){
      this.isLoading = true
      await this.getDayTransactions()
      this.isLoading = false

    },
    async updateMonthTransactions(){
      this.isLoading = true
      await this.getMonthTransactions()
      this.isLoading = false

    },
    // getData(){
    //   this.getStoreDetails()
    //   this.getDayTransactions()
    //   this.getMonthTransactions()
    // }
  },
  created() {
    // console.log(this.$route.params.store_id)
    this.storeId = this.$route.params.store_id
    console.log("initial loading: ", this.isLoading)
    // if(this.$route.params.store_id){
    //     this.isMultiStore = false
    //     this.storeId = this.$route.params.store_id
    //     // console.log("exists")
    //     /
    //     / console.log(this.isMultiStore)
    // }
    // this.getStore();
    // console.log("Store Transaction componenent",this.storeId)
  axios.all([
    this.getDayTransactions(),
    this.getMonthTransactions(),
    this.getStoreDetails()
  ])
  .then(()=>{
    this.isLoading=false
    console.log("final loading: ", this.isLoading)
  })
    // this.getDayTransactions();
    // this.getMonthTransactions();
    // this.getStoreDetails();
    // this.getData()
   
    // this.getData().then(console.log("final call"))
    

    // console.log(this.storeDetail)
    

    // console.log()
  },
  watch: {
    // call again the method if the route changes
    //'$route': 'getStore',
    // $route() {
    //   this.getStore();
    //   this.getTransaction();
    //   // this.TransactionList
    //   console.log("store", this.isMultiStore);
    // },
  },
};
</script>

<style></style>
