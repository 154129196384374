<template>
<div class="d-flex justify-content-end mt-2">
<button @click="monthModal" class="btn btn-sm btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#addMonthTransaction">Add Month Transaction</button>
</div>
  <table class="table customTable">
    <thead>
      <tr>
        <th>Date</th>
        <th class="d-none d-sm-table-cell">Income</th>
        <th class="d-none d-sm-table-cell">Expense</th>
        <th class="d-none d-sm-table-cell">Others</th>
        <th>Detail</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in transactions" :key="item.id">
        <td>{{ moment(item.month_year).format("MMM, YYYY") }}</td>
        <td class="d-none d-sm-table-cell">{{item.income}}</td>
        <td class="d-none d-sm-table-cell">{{item.expense}}</td>
        <td class="d-none d-sm-table-cell">{{item.others}}</td>
        <td>
           <!-- <div v-if="isMonthlyTransaction">
            <router-link
                :to="{
                  name: 'SingleView',
                  params: {transaction_type:transactionType, store_id:item.store_id, date: item.date },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
          </div>
          <div v-else>
              <template v-if="transactionType==='day'">
              <router-link
                :to="{
                  name: 'SingleView',
                  params: {transaction_type:transactionType, store_id:storeId, date: item.date },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
            </template>
          </div> -->
           <router-link
                :to="{
                  name: 'MonthSingleView',
                  params: {store_id:storeId, date: item.month_year },
                  props:{storeDetail: storeDetail}
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
          <!-- <button @click.stop.prevent=""><font-awesome-icon :icon="detailIcon" /></button> -->
        </td>
      </tr>
    </tbody>

  </table>
   <!-- Modal -->
  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="addMonthTransaction" tabindex="-1" aria-labelledby="addDayTransactionLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content bg-theme">
      <div class="modal-header">
        <h5 class="modal-title" id="addMonthTransactionLabel">Add Month Transaction</h5>
        
         <a
            class="fs-2"
            href="#"
            data-bs-dismiss="modal"
            aria-label="Close" 
            id="close-month-modal"
    
          ><BIconXLg /></a>
      </div>
      <div class="modal-body">
        <div v-if="errors && errors.length"  class="alert alert-danger" role="alert">
          <div v-for="error of errors" :key="error">
            {{error.message}}
          </div>
        </div>
        <form @submit.prevent id="addMonthForm">
          <div class="mb-3">
            <label for="date" class="form-label">Date</label>
           <datepicker :upperLimit="lastDateToPick" class="form-control" v-model="picked" />
          </div>
          <div class="mb-3">
            <label for="transaction-type" class="form-label">Select Transaction</label>
           <select class="form-select" v-model="transactType" required="required">
                      <option disabled value="">Select</option>
                      <template v-for="field in formFields" :key="field.id">
                           <option :value="field.id">{{field.title}}</option>
                      </template>
                     
            </select>
          </div>
          <div class="mb-3">
            <label for="amount" class="form-label">Amount</label>
            <input type="number" step="0.01" class="form-control" v-model="amount" required="required">
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-center pb-4">
        <button @click="addTransaction" type="button" class="btn btn-primary rounded-pill me-2">Save</button>
        <button type="button" class="btn btn-outline-primary rounded-pill" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
  <!-- Moldal -->
</template>

<script>
import axios from 'axios';
import { BIconXLg} from 'bootstrap-icons-vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Datepicker from "vue3-datepicker";
import moment from "moment";
export default {
  name: "StoreMonthTable",
  components: {
    FontAwesomeIcon,
    BIconXLg,
    Datepicker
    
  },
  props: ["transactions", "isLoading", "storeId", "storeDetail"],
  emits: ['updateStoreList'],
  // props:{
  //     transactions:Object,
  //     // transactionType:String,
  //     isMultiStore:Boolean,
  //     },
  data() {
    return {
      detailIcon: faEye,
      picked: new Date(),
      lastDateToPick: new Date(),
      errors: [],
      formFields:{},
      transactType: null,
      amount:null
    };
  },
  methods: {
    monthModal(){
      this.picked = new Date()
      this.transactType= null
      this.amount=null
      this.getTransactionTitles()
    },
     getTransactionTitles() {
      axios.get(`store/month/title/store/`+this.storeId+`/`).then((res) => {
        this.errors = []
        this.formFields = res.data;
        console.log("Month Form Fields", this.formFields)
      });
    },  
    async addTransaction(){
      this.errors = []
      let formData= {}
       let date = this.picked
      const offset = date.getTimezoneOffset()
      date = new Date(date.getTime() - (offset*60*1000))
      date = date.toISOString().split('T')[0]

      if(this.transactType===null || this.amount===null) {
         this.errors.push({
                "message": "All fields are required"
        }) 
        return false; 
      }
      formData.transaction_type = this.transactType
      formData.date = date
      formData.amount = this.amount
      formData.store = this.storeId
      formData.user = this.$store.state.user
      console.log(formData)
      await axios.post(`store/month/transaction/`, formData).then((res) => {
        document.getElementById("close-month-modal").click();
         this.$toast.open({
            message: res.data.success,
            type: "success",
          })
          this.$emit('updateStoreList')
      }).catch(e => {
        if(e.response.status===400){
           this.errors.push({
                "message": "Record already exists for this month"
        })  
        }
        else {
           this.errors.push({
                "message": e.response.data.error
        })  
        }
          console.log(e.response.status)
            console.log(e.response.data.status)
             
      });
    }
   
  },
  created(){
    this.moment = moment

  }
};
</script>

<style></style>
