<template>
  <TransactionList
    :transactions="transactions"
    :title="title"
    :url="url"
    :urlTitle="urlTitle"
    :detailUrl="detailUrl"
  />
</template>

<script>
import TransactionList from "@/components/Transaction/List.vue";
import axios from "axios";
export default {
  name: "Home",
  components: {
    TransactionList,
  },
  data() {
    return {
      transactions: null,
      title: "Monthly Transactions",
      url: "Home",
      urlTitle: "Day",
      detailUrl:"SingleMonthTransaction",
    };
  },
  methods: {
    getTransaction() {
      axios
        .get(
          `api/store/month-all-transactions/` + this.$store.state.userId + `/`
        )
        .then((res) => {
          this.transactions = res.data.data;
        });
    },
  },
  created() {
    this.getTransaction();
  },
};
</script>

<style>
</style>