<template>
<div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 login-section-wrapper">
          <div class="brand-wrapper">
            <div class="main-logo fs-1">nubestore</div>
          </div>
          <div class="login-wrapper my-auto">
            <div class="mt-n5" v-if="isLoading">
              <Loading />
            </div>
            <div v-else>
              <div class="row text-danger error-msg" v-if="errors && errors.length">
                <div class="col-2">
                  <BIconExclamationCircle class="fs-1" />
                </div>
                <div class="col-10">
                  <p v-for="error of errors" :key="error" class="text-danger">
                    {{error.message}}
                  </p>
                </div>
              </div>
              <!-- <ul class="list-unstyled m-0 p-0 error-msg" v-if="errors && errors.length">
                <li v-for="error of errors" :key="error" class="text-danger m-0 p-2 ps-0">
                  <BIconExclamationCircle class="fs-3" /> {{error.message}}
                </li>
              </ul>  -->
              <div class="text-success mb-4" v-if="isPasswordChanged"><h5><BIconCheck2Circle class="fs-1"/>Password changed successfully</h5></div>
              <div class="mb-4" v-if="signUpSuccess"><h5 class="text-success"><BIconCheck2Circle class="fs-1"/>Account created</h5>
               <p>We have sent verification email to <strong>{{signUpEmail}}</strong></p> 
              </div>
              <!-- <div v-if="signUpSuccess">
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                  <h4>Account Created</h4>
                    <p>We have sent verification email to <strong>{{signUpEmail}}</strong></p> 
                <p>You can login after your email is verified.</p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
              </div> -->
              <div v-if="forgetSuccess">
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                  <h4>Password Reset</h4>
                    <p>We have sent password reset verification email to <strong>{{resetEmail}}</strong></p> 
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
              </div>
              <div v-if="signUpPage">
                <h1 class="login-title">Register Account</h1>
                <form @submit.prevent="handleSignUp">
                  <div class="form-group mb-3">
                    <label for="Email" class="text-dark">Email</label>
                      <input @change="validateEmail($event)" v-model="signUpEmail" type="email" class="form-control" :class="emailError.invalid" id="signUpEmail">
                      <div class="invalid-feedback">
                        {{emailError.message}}
                      </div>
                  
                  </div>
                  <div class="form-group mb-3">
                    <label for="password" class="text-dark">Password</label>
                      <input v-model="signUpPassword1" type="password" class="form-control" id="signUpPassword1">
                    
                  </div>
                  <div class="form-group mb-3">
                    <label for="password" class="text-dark">Confirm Password</label>
                      <input v-model="signUpPassword2" type="password" class="form-control" id="signUpPassword2">
                    
                  </div>
                  <div class="d-grid mb-5">
                  <button type="submit" class="btn btn-lg btn-primary">&nbsp;&nbsp;&nbsp;Register&nbsp;&nbsp;&nbsp;</button>
                </div>
                </form>
                <p class="login-wrapper-footer-text">Already have an account? <button class="link" @click="loginView">Login here</button></p>
              </div>
              <div v-else-if="forgetPassPage">
                <h1 class="login-title">Reset Password</h1>
                <form @submit.prevent="handleForgetPassword">
                  <div class="form-group mb-3">
                    <label for="Email" class="text-dark">Email</label>
                      <input @change="validateEmail($event)" v-model="resetEmail" type="email" class="form-control" :class="emailError.invalid" id="resetEmail" required>
                       <div class="invalid-feedback">
                        {{emailError.message}}
                      </div>
                  
                  </div>
                  
                  <div class="d-grid mb-5">
                  <button type="submit" class="btn btn-lg btn-primary">&nbsp;&nbsp;&nbsp;Reset Password&nbsp;&nbsp;&nbsp;</button>
                </div>
                </form>
                <button @click="loginView" class="link p-0 pb-4">Login</button>
                <p class="login-wrapper-footer-text">Don't have an account? <button class="link" @click="signUpView">Register here</button></p>
              </div>
          
              <div v-else>
                <h1 class="login-title">Log in</h1>
                <form @submit.prevent="handleLogin" id="loginForm">
                  <div class="form-group mb-3">
                    <label for="Email" class="text-dark">Email</label>
                      <input @change="validateEmail($event)" v-model="email" type="email" class="form-control" :class="emailError.invalid" id="email" required>
                      <div class="invalid-feedback">
                        {{emailError.message}}
                      </div>
                  
                  </div>
                  <div class="form-group mb-3">
                    <label for="password" class="text-dark">Password</label>
                      <input v-model="password" type="password" class="form-control" id="password" required>
                       <div class="invalid-feedback">
                        Password is required
                      </div>
                    
                  </div>
                  <div class="d-grid mb-5">
                  <button type="submit" class="btn btn-lg btn-primary">&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;</button>
                </div>
                </form>
                <button @click="resetPasswordView" class="link p-0 pb-4">Forgot password?</button>
                <p class="login-wrapper-footer-text">Don't have an account? <button @click="signUpView" class="link">Register here</button></p>
              </div>
  
          </div>
            </div>
            
        </div>

        <div class="col-sm-6 px-0 d-none d-sm-block">
          <img src="@/assets/img/login-bg-2.jpg" alt="login image" class="login-img">
        </div>
      </div>
    </div>
    
</template>
<script>
import axios from 'axios'
// import { Form, Field } from 'vee-validate';
import { BIconCheck2Circle, BIconExclamationCircle} from 'bootstrap-icons-vue';
import Loading from '@/components/bootstrap/loading.vue'
export default {
  name: 'Login',
  components: {
    // Form,
    // Field,
    Loading,
    BIconCheck2Circle,
    BIconExclamationCircle
  },

  data(){
      return {
          email:"",
          password:"",
          resetEmail: "",
          signUpEmail: "",
          signUpPassword1: "",
          signUpPassword2:"",
          resetPassword1:"",
          resetPassword2:"",
          errors:[],
          loginPage:true,
          signUpPage:false,
          forgetPassPage:false,
          signUpSuccess:false,
          forgetSuccess:false,
          resetPassworRequest: false,
          resetPasswordPage: false,
          isPasswordChanged:false,
          isLoading:false,
          emailError: {
            message:"",
            invalid:""
          },
      }
  },
  methods:{
    resetForm(){
      this.isPasswordChanged = false
      this.signUpSuccess = false
      this.forgetSuccess = false
      this.email = ""
      this.password = ""
      this.resetEmail = ""
      this.signUpEmail=""
      this.signUpPassword1=""
      this.signUpPassword2=""
    },
    clearLoadingAndErrors(){
      this.isLoading = false
      this.errors = []
      this.emailError = {message:"", invalid:""}

    },
    validateEmail(event){
      let email = event.target.value
      if(!email){
        this.emailError.invalid="is-invalid"
        this.emailError.message = "Email is required"
        return false
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(email)) {
        this.emailError.invalid="is-invalid"
        this.emailError.message = "Invalid email address"
        return false
      }
        this.emailError.invalid="is-valid"
        this.emailError.message = ""
        return true
    },
    removeValidateEmail(){
       this.emailError.invalid=""
       this.emailError.message = ""
    },
      resetPasswordView(){
        this.resetForm()
        this.clearLoadingAndErrors()
        this.removeValidateEmail()
        this.forgetPassPage = true
        this.loginPage = false
        this.signUpPage = false
        this.resetPasswordPage = false
      },
      signUpView(){
        this.resetForm() 
        this.clearLoadingAndErrors()
        this.removeValidateEmail() 
        this.signUpPage = true
        this.forgetPassPage = false
        this.loginPage = false
        this.resetPasswordPage = false
        
      },
      loginView(){
        this.email=""
        this.password=""
        this.removeValidateEmail()
        this.loginPage = true
        this.forgetPassPage = false
        this.signUpPage = false
        this.resetPasswordPage = false
      },
      handleSignUp(){
        this.isLoading = true
        this.signUpSuccess = false
        this.errors=[]
        if(this.signUpPassword1!==this.signUpPassword2){
          this.errors.push({
            'message':"The two password fields didn't match."
          })
          return false
        }
        const formData = {
          email: this.signUpEmail,
          password1: this.signUpPassword1,
          password2: this.signUpPassword2
        }
        console.log(formData)
        axios
        .post('registration/', formData)
        .then(res=>{
          console.log(res.data)
          if(res.status===201){
           this.removeValidateEmail()
           this.clearLoadingAndErrors()
           this.signUpSuccess = true
           this.loginView()
          }
        }).catch(e=>{
          // console.log("error occured")
          // console.log(e.response.data)
          // Object.assign(this.signUpErrors, e.response.data);
          // console.log(this.signUpErrors)
          Object.entries(e.response.data).map(item => {
            // console.log(item[1][0])
            this.errors.push({
                "message": item[1][0]
              })
          })
          

          // this.signUpErrors.push(e.response.data)
        });
      },
      handleLogin(){
        
        this.errors=[]
        //   console.log("api env", process.env.VUE_APP_ROOT_API)
        //   console.log("foo env",process.env.VUE_APP_FOO)
          const formData = {
              email: this.email,
              password: this.password,
          }
          axios
          .post('login/', formData)
          .then(res=>{
             
              // const token = res.data.key
            //   const userId = res.data.user_id
            //   const userName = res.data.username
            // return false
            this.removeValidateEmail()
              this.$store.commit('setToken', res.data.key)
              this.$store.commit('setEmail', this.email)
              axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
              
               axios
              .get('user/').then(res=>{
                  console.log(res)
                  this.$store.commit('setUser', res.data.id)
                  console.log("Login ResponseDAta", res.data)
                });
       
              console.log(this.$store.state.token)
              console.log(this.$store.state.email)
              console.log(this.$store.state.user)
              document.body.classList.remove('login')
              //  return false;
              this.$router.push({ name: "Home" });
          }).catch(e => {
            if(e.response.status===400){
              this.errors.push({
                "message": "Invalid Username or Password"
              })
            }
            else {
              this.errors.push({
                "message": "Something went wrong!"
              })
            }
    })
        //   console.log(this.$store.state.isAuthenticated)
      },
      handleForgetPassword(){
        this.forgetSuccess = false
        this.errors=[]

        const formData = {
          email: this.resetEmail
        }
        axios
        .post('password/reset/', formData)
        .then(res=>{
          console.log(res.data)
          if(res.status===200){
            this.removeValidateEmail()
           this.forgetSuccess = true
           this.loginView()
          }
        }).catch(e=>{
          // console.log("error occured")
          // console.log(e.response.data)
          // Object.assign(this.signUpErrors, e.response.data);
          // console.log(this.signUpErrors)
          Object.entries(e.response.data).map(item => {
            // console.log(item[1][0])
            this.errors.push({
                "message": item[1][0]
              })
          })
          

          // this.signUpErrors.push(e.response.data)
        });

      },
  },
  mounted(){
    document.body.classList.add('login')
    let htmlElement = document.documentElement;
            let theme = localStorage.getItem("theme");

            if (theme === 'dark') {
                htmlElement.setAttribute('theme', 'dark')
                this.darkMode = true
            } else {
                htmlElement.setAttribute('theme', 'light');
                this.darkMode = false
            }
  },
  created(){
    // const query = this.$route.query
    this.isPasswordChanged = this.$store.state.isPasswordChanged
    // if(this.$store.state.resetPassword.status){
    //   this.resetPassworRequest = true
    //   this.resetPasswordPage = true,
    //   this.resetPasswordUid = this.$store.state.resetPassword.uid,
    //   this.resetPasswordToken = this.$store.state.resetPassword.token
    //   this.signUpPage = false
    //   this.forgetPassPage = false
    //   this.loginPage = false
    //    console.log("uid", this.$store.state.resetPassword.uid)
    //     console.log("token", this.$store.state.resetPassword.token)

    // }
    
   

    // const isLoggedin = this.$store.state.isAuthenticated
    // console.log("logged in: ", isLoggedin)
    // if(isLoggedin){
    //    axios
    //     .get('user/').then(res=>{
    //         this.$store.commit('setUser', res.data.id)
    //         console.log("Login ResponseDAta", res.data)
    //       });
    //     return false;
    //   this.$router.push({ name: "Home" });
    // }
    //   console.log("Login after create", this.$store.state.token)
  },
  watch:{
    // email(value){
    //   // binding this to the data value in the email input
    //   this.email = value;
    //   this.validateEmail(this.email);
    // },
    //  signUpEmail(value){
    //   // binding this to the data value in the email input
    //   this.signUpEmail = value;
    //   this.validateEmail(this.signUpEmail);
    // },
    //  resetEmail(value){
    //   // binding this to the data value in the email input
    //   this.resetEmail = value;
    //   this.validateEmail(this.resetEmail);
    // }
    
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.logo {
    width: 185px !important;
    height: 55px !important;
}
.error-msg {
  font-size:1rem !important;
}

</style>
