<template>
  <table class="table customTable">
    <thead>
      <tr>
        <th>Month</th>
        <th>Download</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in transactions" :key="item.date">
        <td>{{ moment(item.date).format("MMM, YYYY") }}</td>
        <td><button @click="download(item.date)" class="btn btn-sm btn-primary">&nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;</button></td>
      </tr>
    </tbody>

  </table>
  <!-- Moldal -->
</template>

<script>
import axios from 'axios';
import moment from "moment";
export default {
  name: "MonthDownload",
  components: {

    
  },
  props:['storeId'],

  // props:{
  //     transactions:Object,
  //     // transactionType:String,
  //     isMultiStore:Boolean,
  //     },
  data() {
    return {
      transactions: null,
    };
  },
  methods: {
   
     getTransactions() {
      axios.get(`store/day/download/`+this.storeId+`/`).then((res) => {
        this.transactions = res.data.results;
        console.log('monthly date', res)
      });
    },  
    download(date){
        console.log(date)
    //     axios.get(`report/excel/`+this.storeId+`/`+date+'/').then((res) => {
    //   });
    const file_name = date+'.xlsx'

      axios({
                url: `report/excel/`+this.storeId+`/`+date+`/`,
                method: 'GET',
                responseType: 'blob', // important
                }).then((response) => {
                    console.log(response.headers['content-disposition'])
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_name); //or any other extension
                document.body.appendChild(link);
                link.click();
                });
            // axios
            // .get(`report/excel/`+store+`/`+newDate+`/`)
            // .then(res=>{
            //     console.log(res)
            // })
            
    }   
  },
  created(){
    this.moment = moment
    this.getTransactions()
    // this.getTransactionTitles()
    // console.log("loading", this.isLoading)
    // console.log("store id in Table", this.storeId)
    //  console.log("store detail Table", this.storeDetail)
  }
};
</script>

<style></style>
