<template>
  <nav
    class="navbar navbar-expand-lg fixed-top"
    aria-label="Main navigation"
  >
    <div class="container-lg">
      <!-- <router-link to="/" class="navbar-brand">
      <slot>App</slot> 
      </router-link> -->
      <slot><span class="main-logo fs-3">nubestore</span></slot> 
      
                <div class="switch ms-auto me-3 mt-2 d-lg-none d-xl-none d-xxl-none">
                    <input type="checkbox" class="switch__input" id="Switch" v-model="darkMode">
                    <label class="switch__label" for="Switch">
                        <span class="switch__indicator"></span>
                    </label>
                </div>
        
      <button
        @click.prevent="showOffcanvasMenu()"
        class="navbar-toggler p-0 border-0"
        type="button"
        id="navbarSideCollapse"
        aria-label="Toggle navigation"
      >
       <span class="toggle-menu">
         <font-awesome-icon :icon="menuIcon" />
       </span>
          
      
      </button>

      <div
        class="navbar-collapse offcanvas-collapse"
        :class="showMenu ? 'open' : ''"
      >
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          
          <li class="nav-item">
            <router-link @click="closeNav" :to="{name:'Home'}" class="nav-link"
              >Store</router-link
            >
          </li>
          <li class="nav-item">
            <router-link @click="closeNav" :to="{name:'Report'}" class="nav-link"
              >Excel</router-link
            >
          </li>
          
           <li class="nav-item">
             <router-link @click="closeNav" to="/settings" class="nav-link"
              >Settings</router-link
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="handleLogout" href="#">
              Logout
            </a>
          </li>
          <li class="nav-item mt-2 ms-4 d-none d-lg-block">
                <div class="switch">
                    <input type="checkbox" class="switch__input" id="Switch" v-model="darkMode">
                    <label class="switch__label" for="Switch">
                        <span class="switch__indicator"></span>
                    </label>
                </div>
          </li>
        </ul>
        <!-- <form class="d-flex"></form> -->
      </div>
    </div>
  </nav>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
     <router-link to="/stores">Stores</router-link> |     
    
  </div> -->
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { BIconGear} from 'bootstrap-icons-vue';
export default {
  name: "CustomNav",
  components: {FontAwesomeIcon,BIconGear},
  data() {
    return {
      showMenu: false,
      darkMode: false,
      menuIcon: faBars,
    };
  },
  methods: {
    handleLogout() {
      this.$store.commit("removeToken");
      this.$router.push({ name: "Login" });
    },
    showOffcanvasMenu() {
      // console.log('clicked')
      this.showMenu ? (this.showMenu = false) : (this.showMenu = true);
    },
    closeNav() {
      this.showMenu = false;
    },
  },
  mounted() {
            // set page title
            // set 'app-background' class to body tag
            // let bodyElement = document.body;
            // bodyElement.classList.add("app-background");

            // check for active theme
            let htmlElement = document.documentElement;
            let theme = localStorage.getItem("theme");

            if (theme === 'dark') {
                htmlElement.setAttribute('theme', 'dark')
                this.darkMode = true
            } else {
                htmlElement.setAttribute('theme', 'light');
                this.darkMode = false
            }
        },
  watch: {
    darkMode: function () {
        // add/remove class to/from html tag
        let htmlElement = document.documentElement;

        if (this.darkMode) {
            localStorage.setItem("theme", 'dark');
            htmlElement.setAttribute('theme', 'dark');
        } else {
            localStorage.setItem("theme", 'light');
            htmlElement.setAttribute('theme', 'light');
        }
        console.log("darkMode",this.darkMode)
    }
  }
};
</script>
<style scoped>
/* li {
  font-size: 1.25rem;
} */
.navbar-dark .navbar-brand {
  color: #c7c7c7;
  font-size: 1rem;
}
</style>
