<template>
  <CustomNav>
     <BackButton :times="1"/> {{ storeDetail.store_name }} | {{ storeDetail.address }}
  </CustomNav>

  <div class="d-flex justify-content-between">
    <div class="fw-bold fs-5">{{ moment(transaction_date).format("DD MMMM, YYYY (dddd)") }} </div>
    <div>
      <button @click="getPrevValue(transactions)" class="fs-5 fw-bold bg-none me-2" data-bs-toggle="modal" data-bs-target="#updateDayTransaction"><BIconPencilFill /></button>
       <button @click="deleteTransactionModal" class="fs-5 fw-bold bg-none" data-bs-toggle="modal" data-bs-target="#deleteDayTransaction"><BIconTrash /></button>
      </div>
    
  </div>
  <hr />

  <table class="table table-striped detailTable">
    <thead>
      <tr>
        <th>Title</th>
        <th class="d-none d-sm-table-cell">Type</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in transactions" :key="item.id">
       
        <td>{{ item.title }}</td>
        <td class="d-none d-sm-table-cell">
          <span v-if="item.transaction_type_title === 1">Income</span>
          <span v-else-if="item.transaction_type_title === 2">Expense</span>
          <span v-else-if="item.transaction_type_title === 3">Others</span>
          <span v-else>Remark</span>
        </td>
        <td>
          <span v-if="item.transaction_type_title === 4">{{item.remark}}</span>
          <span v-else>{{ item.amount }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Modal -->
  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="updateDayTransaction" tabindex="-1" aria-labelledby="addDayTransactionLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content bg-theme">
      <div class="modal-header">
        <h5 class="modal-title" id="updateDayTransactionLabel">Update Transaction</h5>
        
         <a
            class="fs-2"
            href="#"
            data-bs-dismiss="modal"
            aria-label="Close" 
            id="close-update-modal"
    
          ><BIconXLg /></a>
      </div>
      <div class="modal-body">
        <div v-if="errors && errors.length"  class="alert alert-danger" role="alert">
          <div v-for="error of errors" :key="error">
            {{error.message}}
          </div>
        </div>
        <form @submit.prevent id="updateDayForm">
          <div class="d-flex">
        <div>Date:&nbsp;</div>
        <datepicker :upperLimit="lastDateToPick" v-model="transaction_date" />
      </div>
          <table class="table table-striped">
            <thead>
              <th>Title</th>
              <th>Type</th>
              <th>Amount</th>
            </thead>
              <tbody>
               <tr v-for="item in prevItem" :key="item.id">
                <td valign="middle" width="45%">
                  <label :for="item.id">{{ item.title }}</label>
                </td>
                <td valign="middle" width="25%">
                  <span v-if="item.transaction_type_title === 1">Income</span>
                  <span v-else-if="item.transaction_type_title === 2">Expense</span>
                  <span v-else-if="item.transaction_type_title === 3">Others</span>
                  <span v-else>Remark</span>
                </td>
                <td v-if="item.transaction_type_title===4" width="30%">
                  <textarea rows="2"
                    name="remark[]"
                    :id="item.id" 
                    class="transaction form-control"
                    :value="item.remark"
                    >
                  </textarea>
                </td>
                <td v-else width="30%">
                  <input
                    name="array[]"
                    :id="item.id"
                    type="number"
                    step="0.01"
                    class="transaction form-control"
                    :value="item.amount"
                  />
                </td>
          </tr>
              </tbody>
          </table>
        </form>
      </div>
      <div class="d-flex justify-content-center pb-4">
        <button @click="updateTransaction" type="button" class="btn btn-primary rounded-pill me-2">Save</button>
        <button type="button" class="btn btn-outline-primary rounded-pill" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
  <!-- Moldal -->
  <!-- closeModal -->
  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="deleteDayTransaction" tabindex="-1" aria-labelledby="addDayTransactionLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content bg-theme">
      <div class="modal-header border-bottom-0 justify-content-end">
       
        
         <a
            href="#"
            class="fs-2"
            data-bs-dismiss="modal"
            aria-label="Close" 
            id="close-delete-modal"
    
          ><BIconXLg /></a>
      </div>
      <div class="modal-body">
       
         <div class="text-center mt-n5">
            <div class="fs-1 text-danger"><BIconExclamationTriangleFill /></div>
                  <div class="fs-5 fw-light text-secondary mt-2">
                    This will delete the record permanently !
                  </div>
        </div>
      </div>
      <div class="d-flex justify-content-center pb-4">
        <button @click="deleteTransaction" type="button" class="btn btn-danger rounded-pill me-2">Delete</button>
        <button type="button" class="btn btn-outline-primary rounded-pill" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
  <!-- closleModal -->
</template>

<script>
// import { createToaster } from "@meforma/vue-toaster";

// const successToast = createToaster({ type: "success"});
// const errorToast = createToaster({ type: "error"});

// const { Toast } = bootstrap
import axios from "axios";
import CustomNav from "@/components/CustomNav.vue";
import BackButton from "@/components/BackButton.vue";
import Modal from "@/components/bootstrap/modal.vue";
import Datepicker from "vue3-datepicker";
import { BIconXLg, BIconExclamationTriangleFill, BIconPencilFill, BIconTrash } from 'bootstrap-icons-vue';
// import DeleteModal from "@/components/bootstrap/delete-modal.vue";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

export default {
  name: "DayDetail",
  components: {
    CustomNav,
    BackButton,
    Modal,
    Datepicker,
    BIconXLg,
    BIconExclamationTriangleFill,
    BIconPencilFill,
    BIconTrash
  },
  props: [
    "storeDetail",
    "storeId",
    "transaction_date",
    "transactions",
    "urlDate"
  ],
  emits: ["updateDayList", "updateDayTransactions"],
  data() {
    return {
      modalId: "modal-",
      aria: "aria-",
      deleteCloseBtn: "delete-close-",
      bsTraget: "#modal-",
      updateModalId: "update-modal-",
      updateAria: "update-aria-",
      updateCloseBtn: "update-close-",
      updateBsTraget: "#update-modal-",
      lastDateToPick: new Date(),
      prevItem: {},
      prevDate:null,
      errors: []
    };
  },

  methods: {
     closeModal(id) {
      document.getElementById(id).click();
    },
    getPrevValue(transactions){
      console.log("getting initial value: ", transactions)
      // return Object.assign(this.prevItem, transactions);

      this.prevItem = {...this.transactions}
      // this.prevDate = this.transaction_date
      console.log(this.prevItem)
    },
    convertDate(date){
      let offset = date.getTimezoneOffset();
      let new_date = new Date(date.getTime() - offset * 60 * 1000);
      new_date = new_date.toISOString().split("T")[0];
      return new_date
    },
    deleteTransaction(){
      const url_date = this.convertDate(this.urlDate)
      axios.delete(`store/day/transaction/`+this.storeId+`/`+url_date+`/`)
       .then((res) => {
          this.closeModal("close-delete-modal")
          this.$emit('updateDayTransactions')
          this.$router.push({ name: "Transactions", params:{store_id: this.storeId} });
          this.$toast.open({
            message: res.data.success,
            type: "success",
          });
          }).catch(e => {
              this.errors.push({
                "message": e.response.data.error
              })  
      });
    },
    updateTransaction(){
      // console.log("storeId", this.storeId)
      // console.log("UserId", this.$store.state.user)
      // console.log("Add Transaction")
      // var input = document.getElementsByName("array[]");
      // console.log("form data", input[0].id, input[0].value)
      const url_date = this.convertDate(this.urlDate)
      // console.log(url_date)
      // return false
      // console.log(this.prevDate)
      const date = this.convertDate(this.transaction_date);
      // const offset = date.getTimezoneOffset();
      // date = new Date(date.getTime() - offset * 60 * 1000);
      // date = date.toISOString().split("T")[0];
      let formData = [];

      // console.log("submited")
      var input = document.getElementsByName("array[]");
      var remark = document.getElementsByName("remark[]");
      
      for (var i = 0; i < input.length; i++) {
        let form_object = {};
        let amount;

        form_object.date = date;
        if(input[i].value==""){
            amount=0
        } else {
            amount=input[i].value
        }
        
        form_object.amount = parseFloat(amount).toFixed(2);
        form_object.remark = ""
        // form_object.user = this.$store.state.user;
        // form_object.store = this.storeId;
        form_object.id = input[i].id;
        formData.push(form_object);
       
      }
      for (var i = 0; i < remark.length; i++) {
        let form_object = {};
        let remark_data;

        form_object.date = date;
        if(remark[i].value==""){
            remark_data=""
        } else {
            remark_data=remark[i].value
        }
        
        form_object.remark = remark_data;
        form_object.amount = 0;
        // form_object.user = this.$store.state.user;
        // form_object.store = this.storeId;
        form_object.id = remark[i].id;
        formData.push(form_object);
       
      }
      console.log(formData)
      // return false
       axios.put(`store/day/transaction/`+this.storeId+`/`+url_date+`/`, formData)
       .then((res) => {
          // console.log(res);
          // this.resetForm()
          this.closeModal("close-update-modal")
          this.$toast.open({
            message: res.data.success,
            type: "success",
          });
          this.$emit('updateDayList')
          }).catch(e => {
              this.errors.push({
                "message": e.response.data.error
              })  
      });
    },
  
  },
  mounted() {
    //  console.log("Props transactions in mounted", this.transaction_date)
    //   console.log("Props transactions in mounted", this.transactions)
  },
  created() {
    this.moment = moment;
    // this.getPrevValue()
    // // console.log("Single",this.storeId)
    // // this.$toast.show(`Hey! I'm here`);
    // console.log("Props transactions", this.transactions)
  },
};
</script>

<style scoped>
.modal-footer {
  border: none !important;
}

</style>
