<template>
<CustomNav>Stores</CustomNav>

<div class="d-flex flex-wrap flex-column flex-md-row justify-content-center">
    <div class="card bg-theme m-2" v-for="item in store_data" :key="item.id">
       
        <router-link :to="{ name: 'Transactions', params: { store_id: item.id }}">
            
                <div class="card-body text-center">
                    <h4>{{ item.store_name }}</h4>
                    <p>{{item.address}}</p>
                </div> 
              
        </router-link>
        
    </div>
</div>


</template>

<script>
import CustomNav from '@/components/CustomNav.vue'
import BackButton from "@/components/BackButton.vue";
import axios from 'axios'
export default {
    name: "StoreList",
    components: {
        CustomNav,
        BackButton,
    },
    data(){
        return {
            store_data: null,
            form_store_name:'',
            form_store_address:'',
            form_user: this.$store.state.userId,       
        }
    },
    methods: {
        getStoreList(){
            axios
          .get(`store/list/`)
          .then(res=>{
            console.log(res)
             this.store_data = res.data
            //  console.log(this.store_data)
             
          })
        },
    },
    created() {
        this.getStoreList()   
    },

}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #000000;
}
</style>