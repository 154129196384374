<template>
<CustomNav>
    <strong>:(</strong>
  </CustomNav>
  <div class="container">
  <div class="d-flex flex-column justify-content-center text-center mt-5">
    <div class="mb-3">
      <h1 class="text-secondary">:( Page Not Found</h1>
    </div>
    <div><BackButton :times=1 /></div>
  </div>
  </div>
  
</template>

<script>
import CustomNav from "@/components/CustomNav.vue";
import BackButton from "@/components/BackButton.vue";
export default {
    name: 'NotFound',
    components: {
      CustomNav,
      BackButton,
    }

}
</script>

<style>

</style>