import {
  createRouter,
  createWebHistory
} from 'vue-router'
import store from '@/store'
// import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import PasswordReset from '../views/PasswordReset.vue'
import PasswordResetForm from '../views/PasswordResetForm.vue'
import Home from '../views/Home.vue'
import Settings from '../views/Settings.vue'
import Report from '../views/Report.vue'
import DaySingleView from '../views/day/DaySingle.vue'
import Transactions from '../views/store/Transactions.vue'
import MonthSingleView from '../views/month/MonthSingle.vue'
import StoreAllTransaction from '../views/StoreAllTransaction.vue'
import StoreSingleTransaction from '../views/StoreSingleTransaction.vue'
import StoreList from '../views/StoreList.vue'
import StoreDayTransaction from '../views/StoreDayTransaction.vue'
import StoreMonthTransaction from '../views/StoreMonthTransaction.vue'
import MonthTransaction from '../views/MonthTransaction.vue'
import AddDayTransaction from '../views/AddDayTransaction.vue'
import UpdateDayTransaction from '../views/UpdateDayTransaction.vue'
// import SingleDayTransaction from '../views/SingleDayTransaction.vue'
import AddMonthTransaction from '../views/AddMonthTransaction.vue'
import UpdateMonthTransaction from '../views/UpdateMonthTransaction.vue'
import SingleMonthTransaction from '../views/SingleMonthTransaction.vue'
import SingleView from '../views/SingleView.vue'
import NotFound from '../views/404.vue'
// import List from '../views/List.vue'

const routes = [

  {
    path: '/',
    name: 'Home',
    component: StoreList
  },
  // {
  //   path: '/store/:store_id',
  //   name: 'StoreSingleTransaction',
  //   component: StoreSingleTransaction
  // },
  {
      path: '/store/:store_id',
      name: 'Transactions',
      component: Transactions
    },
  {
    path: '/login/:query?',
    name: 'Login',
    component: Login,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
  },
  {
    path:'/password/reset/confirm/:uid/:token/',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path:'/form/password/reset',
    name: 'PasswordResetForm',
    component: PasswordResetForm
  },
  {
    path: '/stores',
    name: 'StoreList',
    component: StoreList,
  },
  {
    path: '/store-day-transaction/:store_id',
    name: 'StoreDayTransaction',
    component: StoreDayTransaction,
  },
  {
    path: '/store-month-transaction/:store_id',
    name: 'StoreMonthTransaction',
    component: StoreMonthTransaction,
  },
  {
    path: '/month-transaction',
    name: 'MonthTransaction',
    component: MonthTransaction,
  },
  {
    path: '/add-day-transaction/:store_id',
    name: 'AddDayTransaction',
    component: AddDayTransaction,
  },
  {
    path: '/update-day-transaction/:store_id/:date/:message?',
    name: 'UpdateDayTransaction',
    component: UpdateDayTransaction,
  },
  {
    path: '/day/:store_id/:date',
    name: 'DaySingleView',
    component: DaySingleView,
  },
  {
    path: '/month/:store_id/:date',
    name: 'MonthSingleView',
    component: MonthSingleView,
  },
  // {
  //   path: '/:transaction_type/:store_id/:date',
  //   name: 'SingleView',
  //   component: SingleView,
  // },
  {
    path: '/add-month-transaction/:store_id',
    name: 'AddMonthTransaction',
    component: AddMonthTransaction,
  },
  {
    path: '/update-month-transaction/:store_id/:date',
    name: 'UpdateMonthTransaction',
    component: UpdateMonthTransaction,
  },
  {
    path: '/single-month-transaction/:store_id/:date',
    name: 'SingleMonthTransaction',
    component: SingleMonthTransaction,
  },
  {
    path: "/:catchAll(.*)",
    name: 'NotFound',
    component: NotFound,

  },

]


const router = createRouter({
  history: createWebHistory(),
  routes
})

// router.beforeEach((to, from) => {
//   if(store.state.isAuthenticated == false) {
//     return { name: 'Login' }
//   } 
// })

router.beforeEach((to, from, next) => {
  if(to.name == 'PasswordReset' && !store.state.isAuthenticated){
    next()
  }
  if(to.name == 'PasswordResetForm' && !store.state.isAuthenticated){
    next()
  }
  else if(to.name !== 'Login' && !store.state.isAuthenticated){
    next({ name: 'Login' })
  }
  else {
    next()
  }
  // if (to.name !== 'Login' && !store.state.isAuthenticated) next({ name: 'Login' })
  // if (to.name !== 'PasswordReset' && !store.state.isAuthenticated) next({ name: 'PasswordReset' })
  // else next()
})


export default router