<template>
  <table class="table customTable mt-4">
    <thead>
      <tr>
        <th>Date</th>
        <th v-if="isMultiStore">Store</th>
        <th class="d-none d-sm-table-cell">Income</th>
        <th class="d-none d-sm-table-cell">Expense</th>
        <th class="d-none d-sm-table-cell">Others</th>
        <th>Detail</th>
      </tr>
    </thead>
    <tbody>
      <tr @click="getDate(item)" v-for="item in transactions" :key="item.id">
        <td>{{ moment(item.month_year).format("MMM, YYYY") }}</td>
        <td v-if="isMultiStore">{{ item.store_title }}</td>
        <td class="d-none d-sm-table-cell">{{item.income}}</td>
        <td class="d-none d-sm-table-cell">{{item.expense}}</td>
        <td class="d-none d-sm-table-cell">{{item.others}}</td>
        <td>
           <!-- <div v-if="isMonthlyTransaction">
            <router-link
                :to="{
                  name: 'SingleView',
                  params: {transaction_type:transactionType, store_id:item.store_id, date: item.date },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
          </div>
          <div v-else>
              <template v-if="transactionType==='day'">
              <router-link
                :to="{
                  name: 'SingleView',
                  params: {transaction_type:transactionType, store_id:storeId, date: item.date },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
            </template>
          </div> -->
           <router-link
                :to="{
                  name: 'MonthSingleView',
                  params: {store_id:item.store, date: item.month_year },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
          <!-- <button @click.stop.prevent=""><font-awesome-icon :icon="detailIcon" /></button> -->
        </td>
      </tr>
    </tbody>

  </table>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
export default {
  name: "MonthTable",
  components: {
    FontAwesomeIcon,
  },
  props: ["transactions", "isMultiStore", "isSingleStore", "isLoading"],
  // props:{
  //     transactions:Object,
  //     // transactionType:String,
  //     isMultiStore:Boolean,
  //     },
  data() {
    return {
      detailIcon: faEye,
    };
  },
  methods: {
    getDate(item) {
      // console.log(item.date)
      // let date = new Date(item.date);
      // let date_from = moment(date).format("YYYY-MM-DD");
      // let date_to = moment(date).add(30, "days").format("YYYY-MM-DD");
      // console.log('from',date_from)
      // console.log('to',date_to)
      // console.log(this.transactionType);
      // console.log(item.store);
      // if (this.transactionType == "day") {
      //   let date = item.date;
      //   console.log("fetching daily details for date :", date);
      //   this.$router.push({
      //     name: "SingleView",
      //     params: {
      //       transaction_type: "day",
      //       store_id: item.store,
      //       date: date,
      //     },
      //   });
      // }
      // if (this.transactionType == "month") {
      //   let date = item.month_year;
      //   console.log("fetching monthly details from date :", date);
      //   this.$router.push({
      //     name: "SingleView",
      //     params: {
      //       transaction_type: "month",
      //       store_id: item.store,
      //       date: date,
      //     },
      //   });
      // }
      // if (this.transactionType == "report") {
      //   console.log("fetching report details from date :", date_from);
      //   this.$router.push({
      //     name: "SingleView",
      //     params: {
      //       transaction_type: "report",
      //       store_id: item.store,
      //       date: date_from,
      //     },
      //   });
      // }
    },
  },
  created(){
    this.moment = moment
    // console.log("loading", this.isLoading)
    // console.log("store id in Table", this.storeId)
  }
};
</script>

<style></style>
