<template>
<CustomNav>{{store_detail.store_name}} | <small>{{store_detail.address}}</small></CustomNav>
<!-- {{transactions}} -->
<div class="d-flex justify-content-between">
    <div class="title">Monthly Transaction</div>
    <div><router-link :to="{ name: 'StoreMonthTransaction', params: { store_id: store_id }}" class="btn btn-sm btn-warning">
Cancel
     </router-link></div>
</div>
<hr>
<div v-if="configTransaction">
<form @submit.prevent="formSubmit" id="transaction">
    <div class="d-flex">
        <div>Date:&nbsp;</div>
        <datepicker :upperLimit="picked" v-model="picked" />
    </div>
    <hr>
    <table class="table table-dark table-striped">
        <thead>
            <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in transactions" :key="item.id"> 
                <td width="40%">
                    <label :for=item.id>{{item.title}}</label>
                </td>
                <td width="25%">
                    <span v-if="item.transaction_type===1">Income</span>
                    <span v-else-if="item.transaction_type===2">Expense</span>
                    <span v-else>Other</span>
                </td>
                <td width="35%">
                    <input name="array[]" :id=item.id type="number" step='0.01' class="transaction form-control" value="">
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-warning">Save</button>
    </div>

</form>
</div>

<div v-else class="text-warning text-center">
You haven't added any Monthly Transaction Title
</div>


</template>

<script>
import axios from 'axios'
import CustomNav from '@/components/CustomNav.vue'
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'

export default {
    name: 'AddMonthTransaction',
    components: {
        CustomNav,
        Datepicker,
    },
    data(){
        return{
            configTransaction: false,
            store_detail: {},
            store_id : null,
            transactions: null,
            picked:ref(new Date()),
        }
    },
    methods: {
        getTransaction(){
            axios
          .get('api/store/month-transactions-titles/')
          .then(res=>{
             this.transactions = res.data.data
             if(res.data.data.length>0){
                 this.configTransaction = true
             }
             console.log(this.transactions)
             
          })
        },
         getStore(){
            axios
          .get(`api/store/single-store/`+this.store_id+`/`)
          .then(res=>{
              this.store_detail = res.data.data
            //   console.log(this.store_id)
            // console.log(res)
             
          })
        },
        formSubmit(){
            let formData=[]
            let date = this.picked
            const offset = date.getTimezoneOffset()
            date = new Date(date.getTime() - (offset*60*1000))
            date = date.toISOString().split('T')[0]
            // console.log("submited")
            var input = document.getElementsByName('array[]');
            // let k;
            for (var i = 0; i < input.length; i++) {
                let form_object = {};
                
                form_object.date = date
                form_object.amount = parseFloat(input[i].value).toFixed(2);
                form_object.transaction_type = parseInt(input[i].id);
                form_object.store =parseInt(this.store_id)
                form_object.user = parseInt(this.$store.state.userId)
                formData.push(form_object)
                console.log(parseFloat(input[i].value).toFixed(2))
                // console.log(val)
            }
            console.log(formData)
            // return false
             axios
          .post(`api/store/month-all-transactions/`+this.$store.state.userId+`/`, formData)
          .then(res=>{
             console.log(res)
             this.$router.push({ name: "StoreMonthTransaction", params: {store_id:this.store_id} });
             
          })
        
        }
    },
    created() {
        this.store_id = this.$route.params.store_id;
        this.getTransaction()
        this.getStore();
       
    },
    // mounted(){
    //     this.store_id = this.$route.params.store_id;
    // }
}
</script>

<style scoped>


</style>