<template>
  <div v-if="show">
    <div
      v-if="isError"
      class="alert alert-danger d-flex align-items-center alert-dismissible fade show"
      role="alert"
    >
      <font-awesome-icon
        :icon="errorIcon"
        size="2x"
        :style="{ color: 'red' }"
      />
      <div class="ms-4 text-danger">
        <slot>Error</slot>
      </div>
      <div>
        <button
          @click="toggleAlert"
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        >
          <font-awesome-icon
            :icon="closeIcon"
            size="2x"
            :style="{ color: 'red' }"
          />
        </button>
      </div>
    </div>
    <div v-else>
      <div
        class="alert alert-success d-flex align-items-center alert-dismissible fade show"
        role="alert"
      >
        <font-awesome-icon
          :icon="successIcon"
          size="2x"
          :style="{ color: 'green' }"
        />
        <div class="ms-4 text-success">
          <strong><slot></slot></strong>
        </div>
        <div>
        <button
          @click="toggleAlert"
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        >
          <font-awesome-icon
            :icon="closeIcon"
            size="2x"
            :style="{ color: 'green' }"
          />
        </button>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "AlertError",
  components: {
    FontAwesomeIcon,
  },
  props: ["isError"],
  data() {
    return {
      show: true,
      errorIcon: faExclamationCircle,
      closeIcon: faTimesCircle,
      successIcon: faCheckCircle,
    };
  },
  methods: {
    toggleArt() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
.btn-close {
  background: none !important;
}
</style>
