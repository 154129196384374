<template>
  <table class="table table-dark table-striped">
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in transactions" :key="item.id">
            <td valign="middle" width="45%">
              <label :for="item.id">{{ item.title }}</label>
            </td>
            <td valign="middle" width="25%">
              <span v-if="item.transaction_type_title === 1">Income</span>
              <span v-else-if="item.transaction_type_title === 2">Expense</span>
              <span v-else>Other</span>
            </td>
            <td width="30%">
              <input
                name="array[]"
                :id="item.id"
                type="number"
                step="0.01"
                class="transaction form-control"
                :value="item.amount"
              />
            </td>
          </tr>
        </tbody>
      </table>

</template>

<script>



export default {
  name: "DayForm",
  components: {


  },
  props:['transactions'],
  data() {
    return {

    };
  },
  methods: {
     
  },
  created() {
  },
  // mounted(){
  //     this.store_id = this.$route.params.store_id;
  // }
};
</script>

<style scoped>

</style>
