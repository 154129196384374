<template>
  <router-view/>
</template>
<script>

import axios from 'axios'
export default{
  name: 'App',
  beforeCreate(){
    // const query = this.$route.query
    // console.log("before create", query)
    // if(query.verified){
    //   console.log("verified params")
    // }
    // else {
    //   console.log("blank params")
    // }
    // console.log("appvue before create", this.$store.state.token)
    // return false
    this.$store.commit('initializeStore')

    const token = this.$store.state.token
    // console.log("appvue after create", this.$store.state.token)

    if(token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = '';
      // this.$router.push({ name: "Login" });
    }
    
  }
}
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"
</script>


<style lang="scss">

</style>
