import { createStore } from 'vuex'
// import auth from '/.modules/auth'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    token: '',
    user:'',
    email:'',
    isLoading: true,
    isAuthenticated: false,
    storeId:null,
    isPasswordChanged:false,
    resetPassword: {
      status:false, 
      uid: "",
      token: "",
    }
  },
  mutations: {
    initializeStore(state){
      if(localStorage.getItem('token')){
        state.token = localStorage.getItem('token')
        state.user = localStorage.getItem('user')
        state.email = localStorage.getItem('email')
        state.isAuthenticated = true
      } else {
        state.token = '',
        state.user='',
        state.email='',
        state.isAuthenticated = false
      }
    },
    setToken(state, token){
      state.token = token
      localStorage.setItem("token", token)
      state.isAuthenticated = true
    },
    setUser(state, user){
      state.user = user
      localStorage.setItem("user", user)
      state.isAuthenticated = true
    },
    setEmail(state, email){
      state.email = email
      localStorage.setItem("email", email)
      state.isAuthenticated = true
    },
    removeToken(state){
      state.token = '',
      state.user='',
      state.email='',
      localStorage.setItem("token", '')
      localStorage.setItem("user", '')
      localStorage.setItem("email", '')
      state.isAuthenticated = false
    },
    startLoading(state){
      state.isLoading = true
    },
    finishLoading(state){
      state.isLoading = false
    },
    setResetPassword(state, obj){
      state.resetPassword.status = true
      state.resetPassword.uid = obj.uid
      state.resetPassword.token = obj.token 
    },
    removeResetPassword(state){
      state.resetPassword.status = false
      state.resetPassword.uid = ""
      state.resetPassword.token = ""
    },
    setIsPasswordChanged(){
      state.isPasswordChanged=true
    },
    removeIsPasswordChanged(){
      state.isPasswordChanged=""
    }

  },
  actions: {
  },
  modules: {
    // auth,
  },
  plugins: [createPersistedState()]
})
