<template>
  <button @click="goBack" class="back fw-bold fs-2"><BIconArrowLeft /></button>
</template>

<script>
import { BIconArrowLeft} from 'bootstrap-icons-vue';
export default {
name: 'BackButton',
props:['times'],
components: {
  BIconArrowLeft
},
methods: {
        goBack() {
          this.$router.go(-this.times);
    },

}
}
</script>

<style>

</style>