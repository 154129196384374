<template>
  <DayDetail

    @updateDayList="getTransaction"
    :storeDetail="storeDetail"
    :storeId="storeId"
    :transaction_date="js_trans_date"
    :urlDate="js_trans_date"
    :transactions="transactions"
  />
</template>

<script>
import moment from "moment"
import axios from "axios";
import SingleList from "@/components/Transaction/Single.vue";
import DayDetail from "@/components/Day/DayDetail.vue";
export default {
  name: "DaySingleView",
  components: {
    SingleList,
    DayDetail,
  },
  data() {
    return {
      storeDetail: {},
      storeId: null,
      transaction_date: null,
      js_trans_date:null,
      transactions: null,
      transactionType:"Daily Transaction",
      updateType:"UpdateDayTransaction",
      isMonthlyTransaction:true,
      isReport:false
    };
  },
  methods: {
    getTransaction() {
     
        this.transactionType = "Daily Transaction"
        this.updateType="UpdateDayTransaction"
        this.isMonthlyTransaction = false
         axios
        .get(
          `store/day/transaction/` +
            this.storeId +
            `/` +
            this.transaction_date +
            `/`
        )
        .then((res) => {
          this.transactions = res.data
          console.log("single day", this.transactions)
        });

     
     
     
    },
    getStore() {
      axios.get(`store/detail/` + this.storeId + `/`).then((res) => {
        this.storeDetail = res.data;
        // console.log("store: ", res.data)
        //   console.log(this.store_id)
        // console.log(res)
      });
    },
    updateTransaction(){
      console.log("Month Trans Updated")
    }
  },
  created() {
    this.storeId = this.$route.params.store_id;
    this.transaction_date = this.$route.params.date;
    this.js_trans_date = new Date(this.$route.params.date);
    // this.date_from = this.$route.params.date_to;
    // console.log(this.$route.params.transaction_type);
    this.getTransaction();
    this.getStore();
    // this.store_name = this.transactions[0]['store_title']
    // console.log("StoreId", this.storeId)
  },
  // mounted(){
  //     this.store_id = this.$route.params.store_id;
  // }
};
</script>

<style></style>
