<template>
<div class="d-flex justify-content-end mt-2">
<button class="btn btn-sm btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#addDayTransaction">
  Add New Transaction
</button>
</div>

  <table class="table customTable">
    <thead>
      <tr>
        <th>Date</th>
        <th class="d-none d-sm-table-cell">Income</th>
        <th class="d-none d-sm-table-cell">Expense</th>
        <th class="d-none d-sm-table-cell">Others</th>
        <th>Detail</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in transactions" :key="item.id">
        <td>{{ moment(item.date).format("DD MMM, YYYY") }}</td>
        <td class="d-none d-sm-table-cell">{{item.income}}</td>
        <td class="d-none d-sm-table-cell">{{item.expense}}</td>
        <td class="d-none d-sm-table-cell">{{item.others}}</td>
        <td>
          <!-- <div v-if="isMonthlyTransaction">
            <router-link
                :to="{
                  name: 'SingleView',
                  params: {transaction_type:transactionType, store_id:item.store_id, date: item.date },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
          </div>
          <div v-else>
              <template v-if="transactionType==='day'">
              <router-link
                :to="{
                  name: 'SingleView',
                  params: {transaction_type:transactionType, store_id:storeId, date: item.date },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
            </template>
          </div> -->
          <router-link
                :to="{
                  name: 'DaySingleView',
                  params: {store_id:storeId, date: item.date },
                }"
              >
                <font-awesome-icon :icon="detailIcon" />
              </router-link>
          <!-- <button class="link" @click.stop.prevent=""><font-awesome-icon :icon="detailIcon" /></button> -->
        </td>
      </tr>
    </tbody>

  </table>

  <!-- Modal -->
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="addDayTransaction" tabindex="-1" aria-labelledby="addDayTransactionLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content bg-theme">
      <div class="modal-header">
        <h5 class="modal-title" id="addDayTransactionLabel">Add New Day Transaction</h5>
        
         <a @click="resetForm"
            href="#"
            class="icon-close"
            data-bs-dismiss="modal"
            aria-label="Close" 
            id="close-modal"
    
          ></a>
      </div>
      <div class="modal-body">
        <div v-if="errors && errors.length"  class="alert alert-danger" role="alert">
          <div v-for="error of errors" :key="error">
            {{error.message}}
          </div>
        </div>
        <form @submit.prevent>
          <div class="d-flex">
        <div>Date:&nbsp;</div>
        <datepicker :upperLimit="lastDateToPick" v-model="picked" />
      </div>
          <table class="table table-striped">
            <thead>
              <th>Title</th>
              <th>Type</th>
              <th>Amount</th>
            </thead>
              <tbody>
               <tr v-for="item in formFields" :key="item.id">
                <td valign="middle" width="45%">
                  <label :for="item.id">{{ item.title }}</label>
                </td>
                <td valign="middle" width="25%">
                  <span v-if="item.transaction_type_title === 1">Income</span>
                  <span v-else-if="item.transaction_type_title === 2">Expense</span>
                  <span v-else-if="item.transaction_type_title === 3">Other</span>
                  <span v-else>Remark</span>
                </td>
                <td v-if="item.transaction_type_title===4" width="30%">
                  <textarea rows="2"
                    name="remark[]"
                    :id="item.id" 
                    class="transaction form-control"
                    value=""
                    >
                  </textarea>
                </td>
                <td v-else width="30%">
                  <input
                    name="array[]"
                    :id="item.id"
                    type="number"
                    step="0.01"
                    class="transaction form-control"
                    value="0"
                  />
                </td>
          </tr>
              </tbody>
          </table>
        </form>
      </div>
      <div class="d-flex justify-content-center pb-4">
        <button @click="addTransaction" type="button" class="btn btn-primary rounded-pill me-2">Save</button>
        <button @click="resetForm" type="button" class="btn btn-outline-primary rounded-pill" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref } from "vue";
import axios from 'axios';
import { BIconPlusLg} from 'bootstrap-icons-vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Datepicker from "vue3-datepicker";
import moment from "moment";
export default {
  name: "StoreDayTable",
  components: {
    FontAwesomeIcon,
    BIconPlusLg,
    Datepicker,
  },
  props: ["transactions", "isMultiStore", "isSingleStore", "isLoading", "storeId", "storeDetail"],
  emits: ['updateStoreList'],
  // props:{
  //     transactions:Object,
  //     // transactionType:String,
  //     isMultiStore:Boolean,
  //     },
  data() {
    return {
      detailIcon: faEye,
      formFields:{},
      formData:{},
      picked: ref(new Date()),
      lastDateToPick: new Date(),
      errors: [],
    };
  },
  methods: {
    resetForm(){
      this.errors = []
      this.picked= ref(new Date())
      var input = document.getElementsByName("array[]");
      var remark = document.getElementsByName("remark[]");
       for (var i = 0; i < input.length; i++) {
         input[i].value="0"
       }
       for (var i = 0; i < remark.length; i++) {
         remark[i].value=""
       }
    },
    closeModal() {
      document.getElementById("close-modal").click();
    },
    addTransaction(){
      // console.log("storeId", this.storeId)
      // console.log("UserId", this.$store.state.user)
      // console.log("Add Transaction")
      // var input = document.getElementsByName("array[]");
      // console.log("form data", input[0].id, input[0].value)
      let date = this.picked;
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      date = date.toISOString().split("T")[0];
      let formData = [];

      // console.log("submited")
      var input = document.getElementsByName("array[]");
      var remark = document.getElementsByName("remark[]");
      
      for (var i = 0; i < input.length; i++) {
        let form_object = {};
        let amount;

        form_object.date = date;
        if(input[i].value==""){
            amount=0
        } else {
            amount=input[i].value
        }
        
        form_object.amount = parseFloat(amount).toFixed(2);
        form_object.remark = ""
        form_object.user = this.$store.state.user;
        form_object.store = this.storeId;
        form_object.transaction_type = input[i].id;
        formData.push(form_object);
       
      }
      for (var i = 0; i < remark.length; i++) {
        let form_object = {};
        let remark_data;

        form_object.date = date;
        if(remark[i].value==""){
            remark_data=""
        } else {
            remark_data=remark[i].value
        }
        
        form_object.remark = remark_data;
        form_object.amount = 0;
        form_object.user = this.$store.state.user;
        form_object.store = this.storeId;
        form_object.transaction_type = remark[i].id;
        formData.push(form_object);
       
      }
      console.log(formData)
      // return false;
       axios.post('store/day/transaction/', formData)
       .then((res) => {
          // console.log(res);
          this.resetForm()
          this.closeModal()
          this.$toast.open({
            message: res.data.success,
            type: "success",
          });
          this.$emit('updateStoreList')
          }).catch(e => {
              this.errors.push({
                "message": e.response.data.error
              })  
    });
    },
     getTransactionTitles() {
      axios.get(`store/day/title/store/`+this.storeId+`/`).then((res) => {
        this.formFields = res.data;
        // console.log("Form Fields", this.formFields)
      });
    },
    getDate(item) {
      // console.log(item.date)
      // let date = new Date(item.date);
      // let date_from = moment(date).format("YYYY-MM-DD");
      // let date_to = moment(date).add(30, "days").format("YYYY-MM-DD");
      // console.log('from',date_from)
      // console.log('to',date_to)
      // console.log(this.transactionType);
      // console.log(item.store);
      // if (this.transactionType == "day") {
      //   let date = item.date;
      //   console.log("fetching daily details for date :", date);
      //   this.$router.push({
      //     name: "SingleView",
      //     params: {
      //       transaction_type: "day",
      //       store_id: item.store,
      //       date: date,
      //     },
      //   });
      // }
      // if (this.transactionType == "month") {
      //   let date = item.month_year;
      //   console.log("fetching monthly details from date :", date);
      //   this.$router.push({
      //     name: "SingleView",
      //     params: {
      //       transaction_type: "month",
      //       store_id: item.store,
      //       date: date,
      //     },
      //   });
      // }
      // if (this.transactionType == "report") {
      //   console.log("fetching report details from date :", date_from);
      //   this.$router.push({
      //     name: "SingleView",
      //     params: {
      //       transaction_type: "report",
      //       store_id: item.store,
      //       date: date_from,
      //     },
      //   });
      // }
    },
  },
  created(){
    this.moment = moment
    this.getTransactionTitles()
    // console.log("loading", this.isLoading)
    // console.log("store id in Table", this.storeId)
  }
};
</script>

<style></style>
