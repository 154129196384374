<template>
<div class="d-md-flex align-items-md-start">
  <ul class="nav nav-tabs with-caret d-md-none" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link active"
        id="pills-one-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-one"
        type="button"
        role="tab"
        aria-controls="pills-one"
        aria-selected="true"
      >
        <slot name="m-title1">Tab One</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-two-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-two"
        type="button"
        role="tab"
        aria-controls="pills-two"
        aria-selected="false"
      >
       <slot name="m-title2">Tab Two</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-Three-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-three"
        type="button"
        role="tab"
        aria-controls="pills-three"
        aria-selected="false"
      >
       <slot name="m-title3">Tab Three</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-Four-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-four"
        type="button"
        role="tab"
        aria-controls="pills-four"
        aria-selected="false"
      >
       <slot name="m-title4">Tab Four</slot>
      </button>
    </li>
  </ul>
<ul class="nav flex-column nav-tabs d-none d-md-block" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link active"
        id="pills-one-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-one"
        type="button"
        role="tab"
        aria-controls="pills-one"
        aria-selected="true"
      >
        <slot name="title1">Tab Three</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link d-block"
        id="pills-two-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-two"
        type="button"
        role="tab"
        aria-controls="pills-two"
        aria-selected="false"
      >
       <slot name="title2">Tab Two</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-Three-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-three"
        type="button"
        role="tab"
        aria-controls="pills-three"
        aria-selected="false"
      >
       <slot name="title3">Tab Three</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-Four-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-four"
        type="button"
        role="tab"
        aria-controls="pills-four"
        aria-selected="false"
      >
       <slot name="title4">Tab Four</slot>
      </button>
    </li>
  </ul>
  <div class="tab-content col-md-8 col-xl-7 setting-card" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="pills-one"
      role="tabpanel"
      aria-labelledby="pills-one"
    >
    <!-- <hr> -->
    <div class="">
      <div class="card p-4">
      <slot name="content1">Content One</slot>
      </div>
    </div>
    </div>
    
    <div
        class="tab-pane fade"
        id="pills-two"
        role="tabpanel"
        aria-labelledby="pills-two"
        > 
    <div class="">
      <div class="card p-4">
      <slot name="content2">Content Two</slot>
      </div>
    </div>
     
    </div>
    <div
        class="tab-pane fade"
        id="pills-three"
        role="tabpanel"
        aria-labelledby="pills-three"
        > 
        <div class="">
        <div class="card p-4">
        <slot name="content3">Content Three</slot>
        </div>
        </div>
     
    </div>
    <div
        class="tab-pane fade"
        id="pills-four"
        role="tabpanel"
        aria-labelledby="pills-four"
        > 
        <div class="">
          <div class="card p-4">
          <slot name="content4">Content Four</slot>
          </div>
        </div>
     
    </div>
  </div>
</div>
  <!-- <ul class="nav nav-tabs" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link active"
        id="pills-one-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-one"
        type="button"
        role="tab"
        aria-controls="pills-one"
        aria-selected="true"
      >
        <slot name="title1">Tab Three</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-two-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-two"
        type="button"
        role="tab"
        aria-controls="pills-two"
        aria-selected="false"
      >
       <slot name="title2">Tab Two</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-Three-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-three"
        type="button"
        role="tab"
        aria-controls="pills-three"
        aria-selected="false"
      >
       <slot name="title3">Tab Three</slot>
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="py-3 nav-link"
        id="pills-Four-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-four"
        type="button"
        role="tab"
        aria-controls="pills-four"
        aria-selected="false"
      >
       <slot name="title4">Tab Four</slot>
      </button>
    </li>
  </ul> -->
  <!-- <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="pills-one"
      role="tabpanel"
      aria-labelledby="pills-one"
    >

    <slot name="content1">Content One</slot>
    </div>
    
    <div
        class="tab-pane fade"
        id="pills-two"
        role="tabpanel"
        aria-labelledby="pills-two"
        > 
        <slot name="content2">Content Two</slot>
     
    </div>
    <div
        class="tab-pane fade"
        id="pills-three"
        role="tabpanel"
        aria-labelledby="pills-three"
        > 
        <slot name="content3">Content Three</slot>
     
    </div>
    <div
        class="tab-pane fade"
        id="pills-four"
        role="tabpanel"
        aria-labelledby="pills-four"
        > 
        <slot name="content4">Content Four</slot>
     
    </div>
  </div> -->
</template>

<script>
export default {
    name: "TabFour"

}
</script>

<style lang="scss" scoped>

</style>