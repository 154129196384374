<template>
<CustomNav></CustomNav>
<div class="col-md-12">
    <div class="row">
        <div class="col-md-3">
    <div class="card mb-4 mb-sm-0">
    <div class="card-body">
        <h6 class="mb-2">Single Day Report</h6>
        <hr>
        <p class="mb-2">Select Date</p>
        <!-- <datepicker :upperLimit="maxDate" class="form-control mb-2" v-model="singleDayDate" /> -->
         <Datepicker v-model="date" :maxDate="maxDate" :format="format" :enableTimePicker=false autoApply />
          <p v-if="day_error" class="text-danger mt-2 mb-n1">Select Store</p>
         <p class="mb-2 mt-2">
            <select class="form-select" aria-label="Default select" id="day-store">
                
                <option value="" selected>Select Store</option>
                <template v-for="item in store_data" :key="item.id" :id="item.id">
                <option :value="item.id">{{item.store_name}}</option>
                
                </template>
            </select>
        </p>
        <div class="d-grid">
            <button @click="downloadDay" class="btn btn-primary" type="button">
                <span class="fs-5 p-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"/>
                    </svg>
                </span> 
                Download</button>
        </div>
    </div>

</div>
</div>
<div class="col-md-3">
<div class="card mb-4 mb-sm-0">
    <div class="card-body">
        <h6 class="mb-2">Month Report</h6>
        <hr>
        <p class="mb-2">Select Month</p>
        <!-- <month-picker-input class="mb-2"></month-picker-input> -->
        <Datepicker v-model="month" :maxDate="maxDate" autoApply monthPicker />
        <p v-if="month_error" class="text-danger mt-2 mb-n1">Select Store</p>
        <p class="mb-2 mt-2">
            <select class="form-select" aria-label="Default select" id="month-store">
                
                <option value="" selected>Select Store</option>
                <template v-for="item in store_data" :key="item.id" :id="item.id">
                <option :value="item.id">{{item.store_name}}</option>
                
                </template>
            </select>
        </p>
        <div class="d-grid">
            <button @click="downloadMonth" class="btn btn-primary" type="button">
                <span class="fs-5 p-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"/>
                    </svg>
                </span> 
                Download</button>
        </div>
    </div>

</div>
</div>
<div class="col-md-6">
<div class="card">
    <div class="card-body">
        <h6 class="mb-2">Range Report</h6>
        <hr>
        <p class="mb-2">Select Date(s)</p>
       <Datepicker v-model="date_range" :maxDate="maxDate" :enableTimePicker=false autoApply range multiCalendars />
       <p v-if="range_error" class="text-danger mt-2 mb-n1">Select Store</p>
         <p class="mb-2 mt-2">
            <select class="form-select" aria-label="Default select" id="range-store">
                
                <option value="" selected>Select Store</option>
                <template v-for="item in store_data" :key="item.id" :id="item.id">
                <option :value="item.id">{{item.store_name}}</option>
                
                </template>
            </select>
        </p>
        <div class="d-grid">
            <button @click="downloadRange" class="btn btn-primary" type="button">
                <span class="fs-5 p-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"/>
                    </svg>
                </span> 
                Download</button>
        </div>
    </div>

</div>
</div>
</div>
</div>

  
</template>

<script>
import moment from "moment"
import axios from 'axios'
import { ref, onMounted } from 'vue'
import CustomNav from "@/components/CustomNav.vue"
// import Datepicker from "vue3-datepicker";
import Datepicker from 'vue3-date-time-picker';
import { MonthPickerInput } from 'vue-month-picker'
import { BIconFileExcel } from 'bootstrap-icons-vue';
export default {
    name: "Report",
    components: {
        CustomNav,
        Datepicker,
        MonthPickerInput,
        BIconFileExcel
        
    },
    setup() {
        const month = ref({ 
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        const date = ref(new Date());
        // const date_range = ref();
        // In case of a range picker, you'll receive [Date, Date]
        const format = (date) => {
            const day = date.getDate().toString().padStart(2, '0');
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${year}-${month}-${day}`;
        };
        // onMounted(() => {
        //     const startDate = new Date();
        //     const endDate = new Date();
        //     date_range.value = [startDate, endDate];
        // });
        
        return {
            format,
            date,
            month,
            // date_range
            
        }
    },
    data(){
        return {
            maxDate: new Date(),
            singleDayDate: new Date(),
            month_error:false,
            day_error:false,
            range_error:false,
            store_data:null,
            date_range:null
        }
    },
    methods: {
        getStoreList(){
            axios
            .get(`store/list/`)
            .then(res=>{
            // console.log(res)
                this.store_data = res.data
            //  console.log(this.store_data)
             
            })
        },
        downloadMonth(){
            this.month_error = false
            let new_month = this.month.month+1
            new_month = new_month.toString().padStart(2, '0')
            const newDate = this.month.year+'-'+new_month+'-01'
            const store = document.getElementById("month-store").value

            var sel = document.getElementById("month-store");
            var store_name= sel.options[sel.selectedIndex].text;
            if(!store){
                this.month_error = true
                return false
            }
            
            const file_name = store_name+'-'+new_month+'-'+this.month.year+'.xlsx'
            // console.log(store)
            // return false
            axios({
                url: `report/excel/`+store+`/`+newDate+`/`,
                method: 'GET',
                responseType: 'blob', // important
                }).then((response) => {
                    console.log(response.headers['content-disposition'])
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_name); //or any other extension
                document.body.appendChild(link);
                link.click();
                });
            // axios
            // .get(`report/excel/`+store+`/`+newDate+`/`)
            // .then(res=>{
            //     console.log(res)
            // })
            
        },
        downloadDay(){
            this.day_error=false
            const date = moment(this.date).format('YYYY-MM-DD')
            const store = document.getElementById("day-store").value

            var sel = document.getElementById("day-store");
            var store_name= sel.options[sel.selectedIndex].text;
            if(!store){
                this.day_error = true
                return false
            }
            
            const file_name = store_name+'-'+date+'.xlsx'
            // console.log(store)
            // return false
            axios({
                url: `report/excel/day/`+store+`/`+date+`/`,
                method: 'GET',
                responseType: 'blob', // important
                }).then((response) => {
                    console.log(response.headers['content-disposition'])
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_name); //or any other extension
                document.body.appendChild(link);
                link.click();
                });
            // axios
            // .get(`report/excel/`+store+`/`+newDate+`/`)
            // .then(res=>{
            //     console.log(res)
            // })
            
        },
         downloadRange(){
            this.range_error=false
            const date_from = moment(this.date_range[0]).format('YYYY-MM-DD')
            const date_to = moment(this.date_range[1]).format('YYYY-MM-DD')
            console.log(date_from, date_to)
            // return false
            // const date = moment(this.date).format('YYYY-MM-DD')
            const store = document.getElementById("range-store").value

            var sel = document.getElementById("range-store");
            var store_name= sel.options[sel.selectedIndex].text;
            if(!store){
                this.range_error = true
                return false
            }
            
            const file_name = store_name+'-'+date_from+' To '+date_to+'.xlsx'
            // console.log(store)
            // return false
            axios({
                url: `report/excel/`+store+`/`+date_from+`/`+date_to+`/`,
                method: 'GET',
                responseType: 'blob', // important
                }).then((response) => {
                    console.log(response.headers['content-disposition'])
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_name); //or any other extension
                document.body.appendChild(link);
                link.click();
                });
            // axios
            // .get(`report/excel/`+store+`/`+newDate+`/`)
            // .then(res=>{
            //     console.log(res)
            // })
            
        },
    },
    mounted(){
        const startDate = new Date();
        const endDate = new Date();
        endDate.setDate(startDate.getDate() + 7);
        this.date_range = [startDate, endDate];
    },
    created(){
        this.getStoreList()
    }


}
</script>

<style>

</style>