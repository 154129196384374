import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import Toaster from "@meforma/vue-toaster";
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/scss/style.scss'



axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

createApp(App)
.use(store)
.use(router, axios)
.use(VueToast, {duration:5000})
.mount('#app')

// const app = createApp({});
// app.use(store);
// app.use(router);
// app.use(axios)
// app.use(VueToast);
// app.mount('#app');