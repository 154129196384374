<template>
  <CustomNav
    >{{ store_detail.store_name }} |
    <small>{{ store_detail.address }}</small></CustomNav
  >
  <!-- {{transactions}} -->
  <div v-if="recordExists">
    <AlertError :isError=true>
      Record alreay exists for <strong>{{moment(new Date(picked)).format('DD MMM, YYYY')}}</strong>
    </AlertError>
  </div>
  <div v-if="successMessage">
    
  </div>
  <div v-else class="d-flex justify-content-between">
    <div class="title">Daily Transaction</div>
    <div>
      <!-- <router-link
        :to="{ name: 'StoreDayTransaction', params: { store_id: store_id } }"
        class="btn btn-sm btn-warning"
      >
        Back
      </router-link> -->
      <BackButton :times=1></BackButton>
    </div>
  </div>
  <hr />
  <div v-if="configTransaction">
    <form @submit.prevent="formSubmit" id="transaction">
      <div class="d-flex">
        <div>Date:&nbsp;</div>
        <datepicker :upperLimit="lastDateToPick" v-model="picked" />
      </div>
      <hr />
      <DayForm :transactions="transactions" />
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-warning">Save</button>
      </div>
    </form>
  </div>

  <div v-else class="text-warning text-center">
    You haven't added any Daily Transaction Title
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CustomNav from "@/components/CustomNav.vue";
import DayForm from "@/components/Transaction/DayForm.vue";
import BackButton from "@/components/BackButton.vue";
import AlertError from "@/components/bootstrap/alert-error.vue";

import Datepicker from "vue3-datepicker";
import { ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheckCircle, faExclamationCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "AddDayTransaction",
  components: {
    CustomNav,
    Datepicker,
    FontAwesomeIcon,
    AlertError,
    BackButton,
    DayForm,
  },
  data() {
    return {
      configTransaction: false,
      store_detail: {},
      store_id: null,
      transactions: null,
      picked: ref(new Date()),
      successIcon: faCheckCircle,
      errorIcon:faExclamationCircle,
      closeIcon:faTimesCircle,
      successMessage: false,
      recordExists: false,
      lastDateToPick: new Date(),
    };
  },
  methods: {
    scrollToTop() {
    window.scrollTo(0,0);
    },
    toggleSuccess() {
      this.successMessage = !this.successMessage;
    },
    toggleError() {
      this.recordExists = !this.recordExists;
    },
    getTransaction() {
      axios.get(`api/store/day-titles/`+ this.$store.state.userId + `/`).then((res) => {
        this.transactions = res.data.data;
        if (res.data.data.length > 0) {
          this.configTransaction = true;
        }
        console.log(res.data.data);
      });
    },
    getStore() {
      axios.get(`api/store/single-store/` + this.store_id + `/`).then((res) => {
        this.store_detail = res.data.data;
        console.log('store',res.data.data)
        // console.log(res)
      });
    },
    formSubmit() {
      let date = this.picked;
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      date = date.toISOString().split("T")[0];

      axios.get(`api/store/day/?date=`+date+`&store=`+this.store_id).then((res) => {
        // console.log(res.data)
        if(res.data.length){
            console.log("record exists")
            this.recordExists=true
            this.scrollToTop()
            return false
        } else {
      let formData = [];

      // console.log("submited")
      var input = document.getElementsByName("array[]");
      
      for (var i = 0; i < input.length; i++) {
        let form_object = {};
        let amount;

        form_object.date = date;
        if(input[i].value==""){
            amount=0
        } else {
            amount=input[i].value
        }

        form_object.amount = parseFloat(amount).toFixed(2);
        form_object.transaction_type = parseInt(input[i].id);
        form_object.store = parseInt(this.store_id);
        form_object.user = parseInt(this.$store.state.userId);
        formData.push(form_object);
        // console.log(form_object.amount)
        // console.log(val)
       
      }
    //    return false
      axios
        .post(
          `api/store/day-all-transactions/` + this.$store.state.userId + `/`,
          formData
        )
        .then((res) => {
          console.log(res);
          this.$router.push({
            name: "UpdateDayTransaction",
            params: { store_id: this.store_id, date: date, message: "ok" },
          });
        });

        }
      });
    //   return false
      
    },
  },
  created() {
    this.store_id = this.$route.params.store_id;
    this.moment = moment
    this.getTransaction();
    this.getStore();
  },
  // mounted(){
  //     this.store_id = this.$route.params.store_id;
  // }
};
</script>

<style scoped>
.btn-close{
    background: none !important;
}
</style>
