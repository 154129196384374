<template>
  <CustomNav>
    {{ store_detail.store_name }}
  </CustomNav>

  <div class="d-flex justify-content-between">
    <div>
      <div v-if="isMonthlyTransaction">
        {{ moment(new Date(transaction_date)).format("MMM, YYYY") }}
      </div>
      <div v-else-if="isReport">
        {{ moment(new Date(transaction_date)).format("MMMM, YYYY") }}
      </div>
      <div v-else>{{ transaction_date }}</div>
    </div>
    <div>{{ transactionType }}</div>

    <div>
      <BackButton :times="1"/>
      <div v-if="isMonthlyTransaction"></div>
      <div v-else>
         <router-link
        :to="{
          name: updateType,
          params: { store_id: storeId, date: transaction_date },
        }"
        class="btn btn-sm btn-warning ms-3"
        >Edit</router-link
      >
      </div>
     
    </div>
  </div>
  <hr />

  <table class="table table-striped detailTable">
    <thead>
      <tr>
        <th v-if="isMonthlyTransaction">Date</th>
        <th>Title</th>
        <th class="d-none d-sm-table-cell">Type</th>
        <th>Amount</th>
        <th v-if="isMonthlyTransaction">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in transactions" :key="item.id">
        <td v-if="isMonthlyTransaction">
         <div class="bg-circle-sm"><span>{{ moment(item.date).format("DD") }}</span></div>
        </td>
        <td>{{ item.title }}</td>
        <td class="d-none d-sm-table-cell">
          <span v-if="item.transaction_type_title === 1">Income</span>
          <span v-else-if="item.transaction_type_title === 2">Expense</span>
          <span v-else>Other</span>
        </td>
        <td>
          <span v-if="isReport">{{ item.total }}</span
          ><span v-else>{{ item.amount }}</span>
        </td>
        <td v-if="isMonthlyTransaction">
          <span class="fs-6">
            <Modal
              @modalOpen="getPrevValue(item)"
              :modal_id="setModal(updateModalId, item.id)"
              :aria="setModal(updateAria, item.id)"
              :close_button="setModal(updateCloseBtn, item.id)"
              :bs_target="setModal(updateBsTraget, item.id)"
            >
              <template v-slot:icon
                ><font-awesome-icon :icon="editIcon"
              /></template>

              <template v-slot:title
                ><div class="fs-4 fw-bold">
                  Update Transaction
                </div></template
              >
              <template v-slot:content>
                <div class="text-center mt-n5">
                  <div class="fs-5 fw-light mt-2">
                    {{ item.title }}
                  </div>
                </div>
                <div class="col-md-6 offset-md-3">
                  <form @submit.prevent class="mt-4">
                    <div class="mb-3 row">
                      <label for="date" class="col-sm-2 col-form-label"
                        >Date</label
                      >
                      <div class="col-sm-10">
                        <datepicker
                          class="form-control"
                          v-model="item.date"
                          
                        />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label for="amount" class="col-sm-2 col-form-label"
                        >Amount</label
                      >
                      <div class="col-sm-10">
                        <input
                          type="number"
                          class="form-control"
                          min="0"
                          step="0.01"
                          id="amount"
                          v-model="item.amount"
                        />
                      </div>
                    </div>
                    <div class="modal-footer justify-content-center pb-4">
                      <button
                        @click="updateMonth(item)"
                        class="btn btn-theme"
                      >
                        Update
                      </button>
                      <button
                        @click="resetForm(item)"
                        type="button"
                        class="btn btn-theme-outline"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </template>
            </Modal>
          </span>
          <span class="ms-4 fs-6">
            <Modal
              :modal_id="setModal(modalId, item.id)"
              :aria="setModal(aria, item.id)"
              :close_button="setModal(deleteCloseBtn, item.id)"
              :bs_target="setModal(bsTraget, item.id)"
            >
              <template v-slot:icon
                ><font-awesome-icon :icon="deleteIcon"
              /></template>

              <template v-slot:title
                ><span class="text-danger fs-1"
                  ><font-awesome-icon :icon="warningIcon" /></span
              ></template>
              <template v-slot:content>
                <div class="text-center mt-n5">
                  <div class="fs-5 fw-light text-secondary mt-2">
                    This will delete the record permanently !
                  </div>
                  <table class="table table-striped detailTable mt-4">
                    <tbody>
                      <tr>
                        <td class="text-secondary">Date</td>
                        <td class="text-secondary">Title</td>
                        <td class="text-secondary">Amount</td>
                      </tr>
                      <tr>
                        <td>{{ moment(item.date).format("DD-MM-YYYY") }}</td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.amount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-footer justify-content-center pb-4">
                  <button @click="deleteItem(item.id)" class="btn btn-theme">
                    Delete
                  </button>
                  <button
                    type="button"
                    class="btn btn-theme-outline"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </template>
            </Modal>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// import { createToaster } from "@meforma/vue-toaster";

// const successToast = createToaster({ type: "success"});
// const errorToast = createToaster({ type: "error"});

// const { Toast } = bootstrap
import axios from "axios";
import CustomNav from "@/components/CustomNav.vue";
import BackButton from "@/components/BackButton.vue";
import Modal from "@/components/bootstrap/modal.vue";
import Datepicker from "vue3-datepicker";
// import DeleteModal from "@/components/bootstrap/delete-modal.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEye,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

export default {
  name: "SingleList",
  components: {
    CustomNav,
    BackButton,
    FontAwesomeIcon,
    Modal,
    Datepicker,
  },
  props: [
    "store_detail",
    "storeId",
    "transaction_date",
    "transactions",
    "transactionType",
    "updateType",
    "isMonthlyTransaction",
    "isReport",
  ],
  emits: ["updateTransaction"],
  data() {
    return {
      detailIcon: faEye,
      editIcon: faEdit,
      deleteIcon: faTrashAlt,
      modalId: "modal-",
      aria: "aria-",
      deleteCloseBtn: "delete-close-",
      bsTraget: "#modal-",
      updateModalId: "update-modal-",
      updateAria: "update-aria-",
      updateCloseBtn: "update-close-",
      updateBsTraget: "#update-modal-",
      warningIcon: faExclamationTriangle,
      maxDate: new Date(),
      prevItem: {},
    };
  },

  methods: {
    getPrevValue(item) {
      // both line of code works
      // this.prevItem = {...item}
      return Object.assign(this.prevItem, item);
    },
    resetForm(item) {
      console.log(this.prevItem);
      const resetData = Object.assign(item, this.prevItem);
      this.prevItem = {};
      console.log(this.prevItem);
      return resetData;
    },
    setModal(string, id) {
      return string + id;
    },
    closeModal(modal, id) {
      let close_id = modal + id;
      document.getElementById(close_id).click();
    },
    goBack() {
      this.$router.go(-2);
    },
    updateItem(id) {
      console.log("id", id);
    },
    deleteTableRow(index) {
      this.transactions.splice(index, 1);
    },
    async updateMonth(item) {
      let formData = {};
      const dateToString = moment(formData.date).format("YYYY-MM-DD");

      formData.id = item.id;
      formData.date = dateToString;
      formData.amount = item.amount;
      formData.user = item.user;
      formData.store = this.storeId;
      formData.transaction_type = item.transaction_type;
      console.log("form data: ", formData);
      // return false

      await axios
        .put(`store/month/transaction/` + formData.id + `/`, formData)
        .then((res) => {
          this.closeModal(this.updateCloseBtn, item.id);
          console.log("server response", res);
          this.$emit("updateTransaction");

          this.$toast.open({
            message: res.data.success,
            type: "success",
          });
        });
    },
    async deleteItem(id) {
      await axios.delete(`store/month/transaction/` + id + `/`).then((res) => {
        this.deleteTableRow(id);
        this.$toast.open({
            message: res.data.success,
            type: "success",
          });
        this.closeModal(this.deleteCloseBtn, id);
      });
    },
  },
  mounted() {},
  created() {
    this.moment = moment;
    console.log("Single",this.storeId)
    // this.$toast.show(`Hey! I'm here`);
  },
};
</script>

<style scoped>
.modal-footer {
  border: none !important;
}

</style>
