<template>
<CustomNav>{{store_detail.store_name}} | <small>{{store_detail.address}}</small></CustomNav>
<!-- {{transactions}} -->
<div v-if="successMessage">
    <div class="alert alert-success d-flex align-items-center alert-dismissible fade show" role="alert">
        <font-awesome-icon :icon="successIcon" size="2x" :style="{ color: 'green' }"/>
        <div class="ms-4 text-success">
            <strong>Daily Transaction {{alertMessage}} Successfully</strong>
        </div>
        <div>
            <button @click="toggleSuccess" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
</div>
<div v-else class="d-flex justify-content-between">
    <div class="title">Daily Transaction</div>
    <div>
        <router-link :to="{ name: 'StoreDayTransaction', params: { store_id: store_id }}" class="btn btn-sm btn-warning">
            Back
        </router-link>
    </div>
</div>
<hr>
<form @submit.prevent="formSubmit" id="transaction">
    
  <div class="d-flex justify-content-between mb-2">
    <div>Title</div>
    <div>
        <div class="d-flex">
            <div>Date:&nbsp;</div>
            <datepicker :upperLimit="dateLimit" v-model="picked" />
        </div>
    </div>
    <div>
     Amount
    </div>
  </div>
  <hr>
  <!-- <div v-for="item in transactions" :key="item.id" class="d-flex justify-content-between mb-2"> 
    <div>
    <label :for=item.id>{{item.transaction_title}}</label>
    </div>
    <div>
      <input name="array[]" :id=item.id type="number" step='0.01' class="transaction form-control" :value=item.amount>
    </div>
  </div> -->
  <DayForm :transactions="transactions" />

  <div class="d-flex justify-content-center">
 <button type="submit" class="btn btn-warning">Update</button>
 </div>

</form>



</template>

<script>
import axios from 'axios'
import CustomNav from '@/components/CustomNav.vue'
import DayForm from '@/components/Transaction/DayForm.vue'
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'UpdateDayTransaction',
    components: {
        CustomNav,
        Datepicker,
        FontAwesomeIcon,    
        DayForm,
    },
    data(){
        return{
            store_detail: {},
            store_id : null,
            transaction_date: null,
            transactions: null,
            picked:ref(new Date()),
            successIcon: faCheckCircle,
            successMessage: false,
            alertMessage: '',
            dateLimit: new Date(),
        }
    },
    methods: {
      scrollToTop() {
    window.scrollTo(0,0);
    },
        toggleSuccess(){
            this.successMessage=!this.successMessage
        },
        getTransaction(){
            axios
          .get(`api/store/day-transactions/`+this.store_id+`/`+this.transaction_date+`/`)
          .then(res=>{
             this.transactions = res.data.data
             console.log(res.data.data)
             
          })
        },
       getStore(){
            axios
          .get(`api/store/single-store/`+this.store_id+`/`)
          .then(res=>{
              this.store_detail = res.data.data
            //   console.log(this.store_id)
            // console.log(res)
             
          })
        },
        formSubmit(){
            let formData=[]
            let date = this.picked
            const offset = date.getTimezoneOffset()
            date = new Date(date.getTime() - (offset*60*1000))
            date = date.toISOString().split('T')[0]
            // console.log("submited")
            var input = document.getElementsByName('array[]');
            let k;
            for (var i = 0; i < input.length; i++) {
                let form_object = {};
                
                form_object.date = date
                form_object.amount = parseFloat(input[i].value).toFixed(2);
                form_object.id = parseInt(input[i].id);
                // form_object.store =parseInt(this.store_id)
                // form_object.user = parseInt(this.$store.state.userId)
                formData.push(form_object)
                // console.log(parseFloat(input[i].value).toFixed(2))
                // console.log(val)
            }
            console.log(formData)
            // return false;
             axios
          .put(`api/store/day-transactions-store/`+this.store_id+`/`, formData)
          .then(res=>{
            this.getTransaction()
            this.alertMessage='Updated'
            this.successMessage = true
            this.scrollToTop()
            
             console.log(res)
            //  this.$router.push({ name: "StoreDayTransaction", params: {store_id:this.store_id} });
             
          })
  
           
        
        }
    },
    created() {
      console.log(this.$route.params.message);
      if(this.$route.params.message){
        this.successMessage = true
        this.alertMessage = 'Created'
      }
        this.store_id = this.$route.params.store_id;
        this.transaction_date = this.$route.params.date;
        // console.log(new Date(this.transaction_date))
        this.picked = new Date(this.transaction_date)
        this.getTransaction()
        this.getStore();
       
    },
    // mounted(){
    //     this.store_id = this.$route.params.store_id;
    // }
}
</script>

<style scoped>


</style>