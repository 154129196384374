<template>
<CustomNav>{{store_detail.store_name}}</CustomNav>
<div class="d-flex justify-content-between">
    <div class="title">
        Monthly Transactions
    </div>
<div>
     <router-link :to="{ name: 'StoreDayTransaction', params: { store_id: store_id }}" class="btn btn-sm btn-warning me-3">
Day
     </router-link>
    
    <router-link :to="{ name: 'AddMonthTransaction', params: { store_id: store_id }}" class="btn btn-sm btn-warning me-3">
Add
     </router-link>
</div>
</div>
<hr>

<table class="table table-dark table-striped">
    <thead>
        <tr>
         <th>Date</th>
         <th>Detail</th>
        </tr>
    </thead>
    <tbody>
     <tr v-for="item in transactions" :key="item.id">
       <td>{{item.date}}</td>
       <td>
           <router-link :to="{ name: 'SingleMonthTransaction', params: { store_id: item.store, date: item.date }}">
<font-awesome-icon :icon="detailIcon" />
     </router-link>
       </td>
       
    </tr>
    </tbody>
</table>


</template>

<script>
import axios from 'axios'
import CustomNav from '@/components/CustomNav.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
export default {
    name: 'StoreMonthTransaction',
    components: {
        CustomNav,
        FontAwesomeIcon,
    },
    data(){
        return{
            store_detail: {},
            store_id: null,
            transactions: null,
            detailIcon: faEye,
        }
    },
    methods: {
        getTransaction(){
            axios
          .get(`api/store/month-transactions-store/`+this.store_id+`/`)
          .then(res=>{
             this.transactions = res.data.data
             console.log(this.transactions)
            //  console.log(this.transactions[0]['store_title'])
             
          })
        },
        getStore(){
            axios
          .get(`api/store/single-store/`+this.store_id+`/`)
          .then(res=>{
              this.store_detail = res.data.data
            //   console.log(this.store_id)
            // console.log(res)
             
          })
        }
    },
    created() {
        this.store_id = this.$route.params.store_id;
        this.getTransaction();
        this.getStore();
        // this.store_name = this.transactions[0]['store_title']
       
    },
    // mounted(){
    //     this.store_id = this.$route.params.store_id;
    // }
}
</script>

<style>

</style>