<template>

  
  <div class="nav-wrapper"><CustomNav><BackButton :times="1"/> {{ storeDetail.store_name }} | {{ storeDetail.address }}</CustomNav></div>

   <!-- <div class="vld-parent">
   <loading :active="isLoading" :is-full-page="fullPage" :opacity=1 :background-color="'#212529'" :color="'#ffc107'" :loader="'dots'" :height=50 :width=50 /> -->
  <div v-if="isLoading">
    <Loading />
  </div>
  <div v-else>
    <TabTwo>
    <template v-slot:title1>
      Transactions  
    </template>
    <template v-slot:title2>
      Excel Report
    </template>
    
    <template v-slot:content1>
      <StoreDayTable 
        @updateStoreList="updateDayTransactions"
        :transactions="dayTransactions"
        :isLoading="isLoading"
        :storeId="storeId"
        :storeDetail="storeDetail"
      />
    </template>
    <template v-slot:content2>
      <MonthDownload
      :storeId="storeId" />
    </template>
  </TabTwo>
  </div>
  
<!-- </div> -->

</template>

<script>
import BackButton from "@/components/BackButton.vue";
import Loading from "@/components/bootstrap/loading.vue"
import CustomNav from "@/components/CustomNav.vue"
import TabTwo from "@/components/bootstrap/tab-2.vue"
// import TabThree from "@/components/bootstrap/tab-3.vue"
import StoreDayTable from "@/components/Day/StoreDayTable.vue"
import StoreMonthTable from "@/components/Month/StoreMonthTable.vue"
import MonthDownload from "@/components/Download/MonthDownload.vue"


export default {
  name: "StoreList",
  components: {
    CustomNav,
    Loading,
    // TabTwo,
    TabTwo,
    StoreDayTable,
    StoreMonthTable,
    BackButton,
    MonthDownload
  },
  props: ["dayTransactions", "monthTransactions", "isLoading", "storeId", "storeDetail"],
  emits: ["updateDayTransactions", "updateMonthTransactions"],
  data() {
    return {

    };
  },
  methods : {
    updateDayTransactions(){
      this.$emit('updateDayTransactions')
    },
    updateMonthTransactions(){
      this.$emit('updateMonthTransactions')
    }
  }
};
</script>

<style scoped>


</style>
