<template>

  
  <div class="nav-wrapper" v-if="isMultiStore"><CustomNav>Recent Transactions</CustomNav></div>
  <div class="nav-wrapper" v-else><CustomNav>{{storeDetail.store_name}}</CustomNav></div>

   <!-- <div class="vld-parent">
   <loading :active="isLoading" :is-full-page="fullPage" :opacity=1 :background-color="'#212529'" :color="'#ffc107'" :loader="'dots'" :height=50 :width=50 /> -->
  <div v-if="isLoading" aria-hidden="true">
    <div class="d-flex align-items-center mb-2 justify-content-center">
      <span class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </span>
      &nbsp;
      <span class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </span>
      &nbsp;
      <span class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </span>
    </div>
    <h5 class="text-center placeholder-glow">
      <span class="placeholder col-6"></span>
    </h5>
    <p class="text-center placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
    </p>
  </div>
  <div v-else>
  <div v-if="isMultiStore">
    <TabTwo>
    <template v-slot:title1>
      Day Transactions  
    </template>
    <template v-if="storeId" v-slot:btn1>
      <router-link :to="{ name: 'AddDayTransaction', params: { store_id: storeId }}" class="btn btn-sm btn-warning me-3">
Add
     </router-link>  
    </template>
    <template v-slot:title2>
      Month Transactions
    </template>
    <template v-if="storeId" v-slot:btn2>
      <router-link :to="{ name: 'AddMonthTransaction', params: { store_id: storeId }}" class="btn btn-sm btn-warning me-3">
Add
     </router-link>  
    </template>
    <template v-slot:btn-2>
      Daily Transactions  
    </template>
    <template v-slot:content1>
      <TableComponent 
        :transactions="dayTransactions"
        :isMultiStore="isMultiStore"
        :transactionType="dayTransactionType"
        :isLoading="isLoading"
      />
    </template>
    <template v-slot:content2>
      <TableComponent 
        :transactions="monthTransactions"
        :isMultiStore="isMultiStore"
        :transactionType="monthTransactionType"
        :isLoading="isLoading"
      />
    </template>
  </TabTwo>
  </div>
  <div v-else>
    <TabTwo>
    <template v-slot:title1>
      Day Transactions  
    </template>
    <template v-if="storeId" v-slot:btn1>
      <router-link :to="{ name: 'AddDayTransaction', params: { store_id: storeId }}" class="btn btn-sm btn-warning me-3">
    Add Day
     </router-link>  
    </template>
    <template v-slot:title2>
      Month Transactions
    </template>
    <template v-if="storeId" v-slot:btn2>
      <router-link :to="{ name: 'AddMonthTransaction', params: { store_id: storeId }}" class="btn btn-sm btn-warning me-3">
Add Month
     </router-link>  
    </template>
    <template v-slot:btn-2>
      Daily Transactions  
    </template>
    <template v-slot:content1>
      <TableComponent 
        :transactions="dayTransactions"
        :isMultiStore="isMultiStore"
        :transactionType="dayTransactionType"
        :storeId="storeId"
      />
    </template>
    <template v-slot:content2>
      <TableComponent 
        :transactions="monthTransactions"
        :isMultiStore="isMultiStore"
        :transactionType="monthTransactionType"
        :storeId="storeId"
      />
    </template>
  </TabTwo>
  </div>
  </div>
<!-- </div> -->

</template>

<script>
import CustomNav from "@/components/CustomNav.vue";
import TableComponent from "@/components/Table.vue"
import TabThree from "@/components/bootstrap/tab-3.vue"
import TabTwo from "@/components/bootstrap/tab-2.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TransactionList",
  components: {
    CustomNav,
    Loading,
    TableComponent,
    FontAwesomeIcon,
    TabTwo,
    TabThree,
  },
  props: ["dayTransactions", "monthTransactions", "isMultiStore", "storeDetail", "storeId", "isLoading"],
  data() {
    return {
      detailIcon: faEye,
      dayTransactionType:"day",
      monthTransactionType:"month",
      reportTransactionType:"report",
      fullPage:false,
    };
  },
};
</script>

<style scoped>


</style>
