<template>

<div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 login-section-wrapper">
          <div class="brand-wrapper">
            <div class="logo"></div>
          </div>
          <div class="login-wrapper my-auto">
            <div class="row text-danger error-msg" v-if="errors && errors.length">
              <div class="col-2">
                <BIconExclamationCircle class="fs-1" />
              </div>
              <div class="col-10">
                <p v-for="error of errors" :key="error" class="text-danger">
                  {{error.message}}
                </p>
              </div>
            </div>

              <div>
                <h5 class="text-success mb-4" v-if="isSuccess"><BIconCheck2Circle class="fs-1"/>{{successMessage}}</h5>
                <h1 class="login-title">Reset Password</h1>
                <form @submit.prevent="handleResetPassword">
                  <div class="form-group mb-3">
                    <label for="password" class="text-dark">Password</label>
                      <input v-model="resetPassword1" type="password" class="form-control" id="resetPassword1">
                    
                  </div>
                  <div class="form-group mb-3">
                    <label for="password" class="text-dark">Confirm Password</label>
                      <input v-model="resetPassword2" type="password" class="form-control" id="resetPassword2">
                    
                  </div>
                  <div class="d-grid mb-5">
                  <button type="submit" class="btn btn-lg btn-primary">&nbsp;&nbsp;&nbsp;Reset&nbsp;&nbsp;&nbsp;</button>
                </div>
                </form>
              </div>
             
          </div>
        </div>

        <div class="col-sm-6 px-0 d-none d-sm-block">
          <img src="@/assets/img/login-bg-2.jpg" alt="login image" class="login-img">
        </div>
      </div>
    </div>
    
</template>
<script>
import axios from 'axios'
// import { Form, Field } from 'vee-validate';
import { BIconCheck2Circle, BIconExclamationCircle} from 'bootstrap-icons-vue';
export default {
  name: 'Login',
  components: {
    // Form,
    // Field,
    BIconCheck2Circle,
    BIconExclamationCircle
  },

  data(){
      return {
        
          resetPassword1:"",
          resetPassword2:"",
          errors:[],
          isSuccess:true,
          successMessage:"Request Verified",
          resetPassworRequest: false,
          resetPasswordPage: false,
          resetPasswordUid: "",
          resetPasswordToken: "",
      }
  },
  methods:{
    
     
      handleResetPassword(){
        this.resetPassworRequest=false
        this.errors=[]
        if(this.resetPassword1!==this.resetPassword2){
          this.errors.push({
            'message':"The two password fields didn't match."
          })
          return false
        }
        const formData = {
          new_password1: this.resetPassword1,
          new_password2: this.resetPassword2,
          uid: this.resetPasswordUid,
          token: this.resetPasswordToken
        }
        axios
        .post('password/reset/confirm/', formData)
        .then(res=>{
          console.log(res.data)
          if(res.status===201){
              this.$store.commit('removeResetPassword')
              this.$store.commit('setIsPasswordChanged')
              this.$router.push({ name: "Login" });
        //    this.passReset = true
        //    this.loginView()
          }
        }).catch(e=>{
          // console.log("error occured")
          // console.log(e.response.data)
          // Object.assign(this.signUpErrors, e.response.data);
          // console.log(this.signUpErrors)
          Object.entries(e.response.data).map(item => {
            // console.log(item[1][0])
            this.errors.push({
                "message": item[1][0]
              })
          })
          

          // this.signUpErrors.push(e.response.data)
        });

        console.log(formData)

      }
  },
  mounted(){
    document.body.classList.add('login')
    let htmlElement = document.documentElement;
            let theme = localStorage.getItem("theme");

            if (theme === 'dark') {
                htmlElement.setAttribute('theme', 'dark')
                this.darkMode = true
            } else {
                htmlElement.setAttribute('theme', 'light');
                this.darkMode = false
            }
  },
  created(){
    // const query = this.$route.query
    if(this.$store.state.resetPassword.status){
      this.resetPassworRequest = true
      this.resetPasswordPage = true,
      this.resetPasswordUid = this.$store.state.resetPassword.uid,
      this.resetPasswordToken = this.$store.state.resetPassword.token
       console.log("uid", this.$store.state.resetPassword.uid)
        console.log("token", this.$store.state.resetPassword.token)

    }
    
   

    // const isLoggedin = this.$store.state.isAuthenticated
    // console.log("logged in: ", isLoggedin)
    // if(isLoggedin){
    //    axios
    //     .get('user/').then(res=>{
    //         this.$store.commit('setUser', res.data.id)
    //         console.log("Login ResponseDAta", res.data)
    //       });
    //     return false;
    //   this.$router.push({ name: "Home" });
    // }
    //   console.log("Login after create", this.$store.state.token)
  },
  watch:{
   
    
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.logo {
    width: 185px !important;
    height: 55px !important;
}
.error-msg {
  font-size:1rem !important;
}

</style>
