<template>
  <MonthDetail
    :storeDetail="storeDetail"
    :storeId="storeId"
    :transaction_date="transaction_date"
    :transactions="transactions"
  />
</template>

<script>
import moment from "moment"
import axios from "axios";
import SingleList from "@/components/Transaction/Single.vue";
import MonthDetail from "@/components/Month/MonthDetail.vue";
export default {
  name: "MonthSingleView",
  components: {
    SingleList,
    MonthDetail,
  },
  data() {
    return {
      storeDetail: {},
      storeId: null,
      transaction_date: null,
      transactions: null,
      transactionType:"Daily Transaction",
      updateType:"UpdateDayTransaction",
      isMonthlyTransaction:true,
      isReport:false
    };
  },
  methods: {
    getTransaction() {
    
    
        this.transactionType = "Monthly Transaction"
        this.updateType="UpdateMonthTransaction"
         axios
        .get(
          `store/month/transaction/` +
            this.storeId +
            `/` +
            this.transaction_date +
            `/`
        )
        .then((res) => {
          // let transactions = []
          // this.transactions = res.data;
          let obj = res.data
          // console.log("month", res.data);
          // this.transactions = res.data
          for(let i=0; i<obj.length; i++){
            let date = new Date(obj[i].date)

            obj[i].date = date
          }
          this.transactions = obj
          
          //  console.log(this.transactions[0]['store_title'])
        });
     
    },
    getStore() {
      axios.get(`store/detail/` + this.storeId + `/`).then((res) => {
        this.storeDetail = res.data;
        console.log("store: ", res.data)
        //   console.log(this.store_id)
        // console.log(res)
      });
    },
    updateTransaction(){
      console.log("Month Trans Updated")
    }
  },
  created() {
    this.storeId = this.$route.params.store_id;
    this.transaction_date = this.$route.params.date;
    // this.date_from = this.$route.params.date_to;
    // console.log(this.$route.params.transaction_type);
    this.getTransaction();
    this.getStore();
    // this.store_name = this.transactions[0]['store_title']
    console.log("StoreId", this.storeId)
  },
  // mounted(){
  //     this.store_id = this.$route.params.store_id;
  // }
};
</script>

<style></style>
