<template>
  <SingleList
    :store_detail="store_detail"
    :store_id="store_id"
    :transaction_date="transaction_date"
    :transactions="transactions"
    :transactionType="transactionType"
    :updateType="updateType"
  />
</template>

<script>
import axios from 'axios'
import SingleList from "@/components/Transaction/Single.vue";
export default {
    name: 'SingleMonthTransaction',
    components: {
        SingleList,
    },
    data(){
        return{
            store_detail: {},
            store_id: null,
            transaction_date:null,
            transactions: null,
            transactionType:"Monthly Transaction",
            updateType:"UpdateMonthTransaction"
        }
    },
    methods: {
        getTransaction(){
            axios
          .get(`api/store/month-transactions/`+this.store_id+`/`+this.transaction_date+`/`)
          .then(res=>{
             this.transactions = res.data.data
             console.log(this.transactions)
            //  console.log(this.transactions[0]['store_title'])
             
          })
        },
        getStore(){
            axios
          .get(`api/store/single-store/`+this.store_id+`/`)
          .then(res=>{
              this.store_detail = res.data.data
            //   console.log(this.store_id)
            // console.log(res)
             
          })
        }
    },
    created() {
        this.transactionType = this.$route.params.transaction_type;
        this.store_id = this.$route.params.store_id;
        this.transaction_date = this.$route.params.date;
        this.getTransaction();
        this.getStore();
        // this.store_name = this.transactions[0]['store_title']
       
    },
    // mounted(){
    //     this.store_id = this.$route.params.store_id;
    // }
}
</script>

<style>

</style>