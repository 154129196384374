<template>

  
  <div class="nav-wrapper"><CustomNav></CustomNav></div>
  

   <!-- <div class="vld-parent">
   <loading :active="isLoading" :is-full-page="fullPage" :opacity=1 :background-color="'#212529'" :color="'#ffc107'" :loader="'dots'" :height=50 :width=50 /> -->
  <div v-if="isLoading">
    <Loading />
  </div>
  <div v-else>
    <TabTwo>
    <template v-slot:title1>
      Day Transactions  
    </template>
    <template v-slot:title2>
      Month Transactions
    </template>
    <template v-slot:content1>
      <DayTable 
        :transactions="dayTransactions"
        :isMultiStore="isMultiStore"
        :isSingleStore="isSingleStore"
        :isLoading="isLoading"
      />
    </template>
    <template v-slot:content2>
      <MonthTable 
        :transactions="monthTransactions"
        :isMultiStore="isMultiStore"
        :isSingleStore="isSingleStore"
        :isLoading="isLoading"
      />
    </template>
  </TabTwo>
  </div>
  
<!-- </div> -->

</template>

<script>
import Loading from "@/components/bootstrap/loading.vue"
import CustomNav from "@/components/CustomNav.vue"
import TabTwo from "@/components/bootstrap/tab-2.vue"
import DayTable from "@/components/Day/DayTable.vue"
import MonthTable from "@/components/Month/MonthTable.vue"


export default {
  name: "HomeList",
  components: {
    CustomNav,
    Loading,
    TabTwo,
    DayTable,
    MonthTable,
  },
  props: ["dayTransactions", "monthTransactions", "isMultiStore", "isSingleStore", "isLoading"],
  data() {
    return {

    };
  },
};
</script>

<style scoped>


</style>
